import { ICreateOrganisationRequestObject } from "interfaces/Organisation/ICreateOrganisationRequest";
import { IUpdateOrganisationRequestObject } from "interfaces/Organisation/IUpdateOrganisationRequest";

export const organisationApiBaseUrl = process.env.REACT_APP_API_BASE_URL!;

export const maxTags: number = 10;

export const defaultCreateOrganisationRequest: ICreateOrganisationRequestObject =
  {
    organisationName: "",
    organisationEmail: "",
    organisationDescription: "",
    organisationPictureUrl: "",
    organisationTags: [],
  };

export const defaultOrganisationErrorState = {
  organisationName: false,
  organisationEmail: false,
};

export const defaultUpdateOrganisationRequest: IUpdateOrganisationRequestObject =
  {
    organisationName: "",
    organisationEmail: "",
    organisationDescription: "",
    organisationPictureUrl: "",
    organisationTags: [],
    suspended: false,
    archived: false,
  };
