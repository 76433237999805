import { AxiosResponse } from "axios";
import EdifyPrivateService from "./EdifyPrivateService";

class ReportService extends EdifyPrivateService {
  getMigrationReport(reportName: string): Promise<AxiosResponse<any>> {
    return this.http.get(`v1/reports/migration/${reportName}`);
  }
}

export default ReportService;
