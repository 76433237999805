import { IAuthAction } from "interfaces/Auth/IAuthAction";
import { AuthActionTypes } from "./authActionTypes";

export function authInit(isAuthenticated: boolean): IAuthAction {
  return {
    type: AuthActionTypes.AUTH_INIT,
    data: { authenticated: isAuthenticated },
  };
}

export function authRequest(): IAuthAction {
  return {
    type: AuthActionTypes.AUTH_REQUEST,
  };
}

export function authSuccess(token: string): IAuthAction {
  return {
    type: AuthActionTypes.AUTH_SUCCESS,
    data: token,
  };
}

export function authError(error: string): IAuthAction {
  return {
    type: AuthActionTypes.AUTH_ERROR,
    error,
  };
}

export function logoutRequest(): IAuthAction {
  return {
    type: AuthActionTypes.LOGOUT_REQUEST,
  };
}
