import axios, { Axios } from "axios";

class EdifyPrivateApiService {
  baseUrl: string;
  contentType: string = "application/json";
  http: Axios;

  constructor(baseUrl: string, accessToken: string) {
    this.baseUrl = baseUrl;

    this.http = axios.create({
      baseURL: this.baseUrl,
      headers: {
        "Content-type": this.contentType,
      },
    });

    this.http.interceptors.request.use(function (config) {
      if (
        accessToken !== null &&
        accessToken !== undefined &&
        accessToken !== ""
      ) {
        config.headers.Authorization = `bearer ${accessToken}`;
      }

      return config;
    });
  }
}

export default EdifyPrivateApiService;
