import { useState, useEffect, useContext } from "react";
import { Button, Card, Stack, Table, Form, Row } from "react-bootstrap";
import AccountEntryComponent from "./AccountListEntry";
import { useNavigate } from "react-router-dom";
import { IAccount } from "interfaces/Account/IAccount";
import Loader from "components/Loaders/Loader";
import { TabOptions } from "helpers/enums/TabOptions";
import { SortOptions } from "helpers/enums/SortOptions";
import {
  filterAccountsByLicenceType,
  filterAccountsByString,
  sortAccounts,
} from "helpers/Account/AccountHelpers";
import LicenceOptionMapper from "components/Mappers/LicenceOptionMapper";
import { TokenContext } from "helpers/constants/Contexts";
import AlertMessage from "components/Message/AlertMessage";
import { refreshStates } from "redux/states/localStorage";
import { useDispatch } from "react-redux";

const AccountsList = ({
  accountsState,
  activeTab,
  licenceTypes,
  selectedLicenceTypeId = null,
  filterPrefix = "account",
  addAccountUrl,
  editAccountUrl,
  disabled = false,
}) => {
  //#region constants
  const accessToken = useContext(TokenContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState(
    sessionStorage.getItem(`${filterPrefix}SearchValue_filter`) !== null ||
      undefined
      ? sessionStorage.getItem(`${filterPrefix}SearchValue_filter`)
      : ""
  );
  const [sortBy, setSortBy] = useState(
    sessionStorage.getItem(`${filterPrefix}SortBy_filter`) !== null || undefined
      ? sessionStorage.getItem(`${filterPrefix}SortBy_filter`)
      : SortOptions.Ascending
  );
  const [licenceTypeId, setLicenceType] = useState(
    sessionStorage.getItem(`${filterPrefix}LicenceType_filter`) !== null ||
      undefined
      ? sessionStorage.getItem(`${filterPrefix}LicenceType_filter`)
      : "all"
  );
  const [accountsBase, setAccountsBase] = useState<IAccount[]>(
    accountsState.accounts
  );
  const [accountsDisplay, setAccountsDisplay] = useState<IAccount[]>(
    accountsState.accounts
  );
  //#endregion

  //#region useEffects
  useEffect(() => {
    if (
      activeTab === TabOptions.OrganisationDetail ||
      activeTab === TabOptions.Organisations
    ) {
      resetFilters();
    }
  }, [activeTab]);

  useEffect(() => {
    setAccountsBase(accountsState.accounts);
    setAccountsDisplay(accountsState.accounts);
  }, [accountsState.accounts]);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, licenceTypeId, sortBy, accountsBase]);

  useEffect(() => {
    if (selectedLicenceTypeId) {
      setLicenceType(selectedLicenceTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLicenceTypeId]);
  //#endregion

  //#region functions
  const applyFilters = () => {
    let filteredAccounts = filterAccountsByString(searchValue!, accountsBase);
    //reapply any licenceType filter
    filteredAccounts = filterAccountsByLicenceType(
      licenceTypeId!,
      filteredAccounts
    );
    //finally apply the sort
    setAccountsDisplay(sortAccounts(filteredAccounts, sortBy!));
    sessionStorage.setItem(`${filterPrefix}SearchValue_filter`, searchValue!);
    sessionStorage.setItem(`${filterPrefix}LicenceType_filter`, licenceTypeId!);
    sessionStorage.setItem(`${filterPrefix}SortBy_filter`, sortBy!);
  };

  const resetFilters = () => {
    setSearchValue("");
    setSortBy(SortOptions.Ascending);
    setLicenceType("all");
  };

  const onSearchUpdated = (event) => {
    setSearchValue(event.target.value);
  };

  const onLicenceTypeUpdated = (event) => {
    setLicenceType(event.target.value);
  };

  const onSortByUpdated = (event) => {
    setSortBy(event.target.value);
  };
  //#endregion

  return (
    <>
      <div className="tab_content">
        <Stack direction="vertical" gap={4}>
          <Stack direction="horizontal" gap={4}>
            <h3 className="list_heading">User Accounts</h3>

            <Button
              onClick={() => navigate(`${addAccountUrl}`)}
              className="ms-auto"
              disabled={disabled}
            >
              Add Account
            </Button>
          </Stack>
          <Stack direction="horizontal" gap={4}>
            <Button variant="link" onClick={resetFilters}>
              Reset Filters
            </Button>
            <Button
              variant="link"
              onClick={() => refreshStates(dispatch, accessToken)}
            >
              Refresh
            </Button>
          </Stack>
          <Form>
            <Stack direction="horizontal" gap={5}>
              <Form.Group as={Row}>
                <Stack direction="horizontal" gap={3}>
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Dynamic Search"
                    name="search"
                    value={searchValue!}
                    onChange={onSearchUpdated}
                    disabled={
                      accountsState.loading || accountsState.error !== undefined
                    }
                  />
                </Stack>
              </Form.Group>
              <Form.Group className="ms-auto">
                <Stack direction="horizontal" gap={3}>
                  <Form.Label>Licence&nbsp;Type</Form.Label>
                  <Form.Select
                    size="lg"
                    name="licenceType"
                    placeholder="Select"
                    onChange={onLicenceTypeUpdated}
                    value={licenceTypeId!}
                    disabled={
                      accountsState.loading || accountsState.error !== undefined
                    }
                  >
                    <option value="all">All</option>
                    <LicenceOptionMapper licenceTypes={licenceTypes} />
                  </Form.Select>
                </Stack>
              </Form.Group>
              <Form.Group>
                <Stack direction="horizontal" gap={3}>
                  <Form.Label>Sort&nbsp;By</Form.Label>
                  <Form.Select
                    size="lg"
                    name="sortBy"
                    placeholder="Select"
                    value={sortBy!}
                    onChange={onSortByUpdated}
                    disabled={
                      accountsState.loading || accountsState.error !== undefined
                    }
                  >
                    <option value="ascending">Ascending</option>
                    <option value="descending">Descending</option>
                  </Form.Select>
                </Stack>
              </Form.Group>
            </Stack>
          </Form>

          <Stack direction="horizontal" gap={4}></Stack>
          {accountsState.loading ? (
            <Loader />
          ) : accountsState.error ? (
            <AlertMessage
              success={false}
              title={"Error"}
              message={accountsState.error}
            />
          ) : (
            <Card>
              <div className="list_content">
                <Table>
                  <thead className="table_head">
                    <tr>
                      <th>Name</th>
                      <th>Organisation</th>
                      <th>Status</th>
                      <th>Licence</th>
                      <th>Expiry</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table_body">
                    {accountsDisplay?.map((account) => {
                      return (
                        <AccountEntryComponent
                          key={account.accountId}
                          account={account}
                          accessToken={accessToken}
                          editAccountUrl={`${editAccountUrl}${account.accountId}`}
                          searchValue={searchValue}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card>
          )}
        </Stack>
      </div>
    </>
  );
};

export default AccountsList;
