import {
  fetchHandleGetAccessToken,
  tokenValid,
} from "helpers/Auth/AuthHelpers";
import {
  auditApiBaseUrl,
  defaultGetAuditLogsQueryParams,
} from "helpers/constants/AuditConstants";
import { IAuditLog } from "interfaces/Audit/IAuditLog";
import { IGetAuditLogs } from "interfaces/Audit/IGetAuditLogs";
import { IServiceResponse } from "interfaces/General/IServiceResponse";
import AuditService from "services/AuditService";

export function fetchHandleGetAuditLogs(
  accessToken: string,
  entityId: string,
  onSuccess: (data: IAuditLog[]) => void,
  onFailure: (message: string) => void,
  query?: IGetAuditLogs
) {
  return async function fetchHandleGetAuditLogsThunk(dispatch) {
    try {
      //validate token refresh if needed
      if (!tokenValid(accessToken)) {
        await dispatch(fetchHandleGetAccessToken()).then(
          (token) => (accessToken = token)
        );
      }
      const auditService = new AuditService(auditApiBaseUrl, accessToken);

      if (!query) {
        query = defaultGetAuditLogsQueryParams;
      }
      const response = await auditService.getAuditLogs(entityId, query);
      const serviceResponse = response.data as IServiceResponse<IAuditLog[]>;
      onSuccess(serviceResponse.data);
    } catch (error) {
      const errorMessage = `Failed to get audit logs. Error: ${error}`;
      onFailure(errorMessage);
    }
  };
}

export function toCamelCase(key, value) {
  if (value && typeof value === "object") {
    for (var k in value) {
      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
        delete value[k];
      }
    }
  }
  return value;
}

export function sortAuditByTimestamp(auditLogs: IAuditLog[]) {
  const sortedArray = auditLogs.sort((a, b) => {
    return new Date(a.timestamp).getTime() > new Date(b.timestamp).getTime()
      ? -1
      : 1;
  });
  return sortedArray;
}
