import {
  tokenValid,
  fetchHandleGetAccessToken,
} from "helpers/Auth/AuthHelpers";
import { licenseApiBaseUrl } from "helpers/constants/LicenceConstants";
import { IServiceResponse } from "interfaces/General/IServiceResponse";
import { ILicenceType } from "interfaces/Licence/ILicenceType";
import {
  getLicencesError,
  getLicencesRequest,
  getLicencesSuccess,
} from "redux/actions/licence/licenceActions";
import LicenceService from "services/LicenceService";

export function fetchHandleGetLicenceTypes(accessToken: string) {
  return async function fetchHandleGetLicenecTypesThunk(dispatch) {
    dispatch(getLicencesRequest());

    try {
      //validate token refresh if needed
      if (!tokenValid(accessToken)) {
        await dispatch(fetchHandleGetAccessToken()).then(
          (token) => (accessToken = token)
        );
      }
      const licenceService = new LicenceService(licenseApiBaseUrl, accessToken);

      const response = await licenceService.getLicenceTypes();

      const serviceResponse = response.data as IServiceResponse<ILicenceType[]>;

      dispatch(getLicencesSuccess(serviceResponse.data));
    } catch (error) {
      const errMessage = `Failed to get license types list. Error: ${error}`;
      dispatch(getLicencesError(errMessage));
    }
  };
}
