import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormFooter from "../Footers/FormFooter";
import { IUpdateAccountRequest } from "../../interfaces/Account/IUpdateAccountRequest";
import { useNavigate, useParams } from "react-router-dom";
import AlertMessage from "../Message/AlertMessage";
import Loader from "../Loaders/Loader";
import {
  defaultAccountErrorState,
  defaultUpdateAccountRequest,
} from "helpers/constants/AccountConstants";
import { IFile } from "interfaces/General/IFile";
import {
  fetchHandleDeleteAccount,
  fetchHandleUpdateAccount,
} from "helpers/Account/AccountHelpers";
import { getUploadToken, putData, readFile } from "helpers/Files/FileHelpers";
import { IButtonProps } from "interfaces/General/IButtonProps";
import { Form, Stack } from "react-bootstrap";
import LicenceOptionMapper from "components/Mappers/LicenceOptionMapper";
import OrganisationOptionMapper from "components/Mappers/OrganisationOptionMapper";
import ImageSelector from "./ImageSelector";
import { capitaliseString } from "helpers/GeneralHelpers";
import ModalComponent from "components/Modal/ModalComponent";
import { TokenContext } from "helpers/constants/Contexts";
import { IAccount } from "interfaces/Account/IAccount";
import { IAccountsState } from "interfaces/Account/IAccountsState";
import { ILicenceState } from "interfaces/Licence/ILicenseState";
import { IOrganisationsState } from "interfaces/Organisation/IOrganisationsState";
import moment from "moment";
import {
  accountsStateSelector,
  licensesStateSelector,
  organisationsStateSelector,
} from "redux/states/stateSelectors";
import Status from "components/General/Status";
import SvgBtn from "components/General/SvgBtn";
import infoBtn from "assets/images/info_btn.svg";
import AccountAuditWindow from "components/Audit/AccountAuditWindow";

const EditAccountComponent = () => {
  //#region states
  const accessToken = useContext(TokenContext);
  const accountsState: IAccountsState = useSelector(accountsStateSelector);
  const licenceTypesState: ILicenceState = useSelector(licensesStateSelector);
  const organisationsState: IOrganisationsState = useSelector(
    organisationsStateSelector
  );
  //#endregion

  //#region useStates
  const [account, setAccount] = useState<IUpdateAccountRequest>(
    defaultUpdateAccountRequest
  );
  const [accountChanges, setAccountChanges] = useState<IUpdateAccountRequest>();
  const [baseAccount, setBaseAccount] = useState<IUpdateAccountRequest>();
  const [accountFromState, setAccountFromState] = useState<IAccount>();
  const [initials, setInitials] = useState<string>();
  const [cancelUrl, setCancelUrl] = useState<string>();
  const [imageToUpload, setImageToUpload] = useState<IFile | null>();
  const [validated, setValidated] = useState(false);
  const [errorState, setErrorState] = useState(defaultAccountErrorState);
  const [disabled, setDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState<string>();
  const [failure, setFailure] = useState(false);
  const [orgArchived, setOrgArchived] = useState(false);
  const [orgSuspended, setOrgSuspended] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [showAudit, setShowAudit] = useState(false);
  //#endregion

  //#region constants
  const { accountId, orgId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();
  const primaryBtn: IButtonProps = {
    text: "Save Changes",
    onClick: () => handleSubmit(onSubmit)(),
    disabled: disabled || !changesMade,
  };
  const secondaryBtn: IButtonProps = {
    text: "Cancel",
    onClick: () => onCancel(),
    disabled: disabled,
  };
  //#endregion

  //#region useEffects
  useEffect(() => {
    if (!orgId) {
      setCancelUrl("/organisations");
    } else {
      setCancelUrl(`/organisations/${orgId}`);
    }
    let acc: IAccount = accountsState.accounts.filter(
      (a) => a.accountId === accountId
    )[0];
    if (!acc) {
      navigate("/organisations");
      return;
    }
    setAccountFromState(acc);
    let formAcc: IUpdateAccountRequest = {
      email: acc.email,
      firstName: capitaliseString(acc.firstName),
      lastName: capitaliseString(acc.lastName),
      profilePictureUrl: acc["profilePictureUrl"],
      licenceTypeId: acc["licence"]["licenceTypeId"],
      licenceExpiry: acc["licence"]["nextInvoiceDate"],
      organisationId: acc["organisation"]?.["organisationId"],
      suspended: acc.suspended,
      archived: acc.archived,
    };
    setAccount(formAcc);
    setBaseAccount(formAcc);
    setInitials(
      `${Array.from(acc.firstName)[0]}${Array.from(acc?.lastName)[0]}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validate();
    validateOrg();
    if (JSON.stringify(account) !== JSON.stringify(baseAccount)) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (validated) {
      setMessage("Please fill out the required fields.");
      setFailure(true);
      setShowMessage(true);
      window.scrollTo(0, 0);
    }
  }, [validated]);
  //#endregion

  //#region functions
  const handleChange = (event) => {
    setAccount({ ...account!, [event.target.name]: event.target.value });
    setAccountChanges({
      ...accountChanges!,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckChange = (event) => {
    setAccount({ ...account!, [event.target.name]: event.target.checked });
    setAccountChanges({
      ...accountChanges!,
      [event.target.name]: event.target.checked,
    });
  };

  const handleImageChange = (event) => {
    const file: IFile = {
      path: event.target.files[0],
      name: event.target.files[0].name,
    };
    const fileUrl = URL.createObjectURL(event.target.files[0]);
    setImageToUpload(file);
    setAccount({
      ...account,
      profilePictureUrl: fileUrl,
    });
    setAccountChanges({
      ...accountChanges!,
      profilePictureUrl: fileUrl,
    });
  };

  const handleImageDelete = () => {
    setAccount({
      ...account,
      profilePictureUrl: "",
    });
    setAccountChanges({
      ...account,
      profilePictureUrl: "",
    });
    setImageToUpload(null);
  };

  const onSubmit = () => {
    if (accountChanges) {
      if (accountChanges.archived) {
        sendDeleteAccountRequest();
      } else if (
        !errorState.firstName &&
        !errorState.lastName &&
        !errorState.licenceType &&
        !errorState.licenseExpiry &&
        !orgArchived
      ) {
        sendUpdateAccountRequest(accountChanges);
      } else if (!orgArchived) {
        setValidated(true);
      }
    }
  };

  const validate = () => {
    setErrorState({
      ...errorState,
      firstName: accountChanges?.firstName?.trim().length === 0,
      lastName: accountChanges?.lastName?.trim().length === 0,
      licenceType: accountChanges?.licenceTypeId?.trim().length === 0,
      licenseExpiry:
        accountChanges?.licenceExpiry?.trim().length === 0 ||
        moment.utc(accountChanges?.licenceExpiry).isBefore(moment.utc()),
    });
  };

  const validateOrg = () => {
    const organisation = organisationsState.organisations.find(
      (org) => org.organisationId === account.organisationId
    );
    setMessage(
      organisation?.suspended! || organisation?.archived!
        ? `The organisation this account belongs to is ${
            organisation?.archived! ? "archived" : "suspended"
          }`
        : ""
    );
    setOrgArchived(organisation?.archived!);
    setOrgSuspended(organisation?.suspended!);
    setShowMessage(organisation?.suspended! || organisation?.archived!);
    setFailure(organisation?.suspended! || organisation?.archived!);
  };

  const updateAccount = async (accountToUpdate: IUpdateAccountRequest) => {
    dispatch(
      fetchHandleUpdateAccount(
        accessToken,
        accountToUpdate,
        accountId!,
        baseAccount!,
        () => navigate(cancelUrl!),
        (message) => onUpdateAccountError(message)
      )
    );
  };

  const onUpdateAccountError = (message: string) => {
    setDisabled(false);
    setMessage(message);
    setFailure(true);
    setShowMessage(true);
    window.scrollTo(0, 0);
  };

  const sendDeleteAccountRequest = async () => {
    setValidated(false);
    setDisabled(true);

    await dispatch(
      fetchHandleDeleteAccount(
        accessToken,
        accountId!,
        () => navigate(cancelUrl!),
        (message) => onUpdateAccountError(message)
      )
    );
  };

  const sendUpdateAccountRequest = async (account: IUpdateAccountRequest) => {
    setValidated(false);
    setDisabled(true);
    try {
      if (imageToUpload) {
        const uploadUrl = await getUploadToken(
          accessToken,
          accountId!,
          imageToUpload.name
        );
        const fileData = await readFile(imageToUpload);
        await putData(fileData, uploadUrl);
        account.profilePictureUrl = uploadUrl.split("?")[0];
      }
      await updateAccount(account);
    } catch (e) {
      console.log(e);
      onUpdateAccountError("Failed to send the account update request.");
    }
  };

  const onCancel = () => {
    if (changesMade) {
      setShowAlert(true);
    } else {
      navigate(cancelUrl!);
      return;
    }
  };

  const handleClose = () => {
    setShowAlert(false);
  };
  //#endregion

  return (
    <>
      <div className="form-page">
        {disabled ? (
          <Loader />
        ) : (
          <div className="vstack gap-4 col-md-5 mx-auto">
            <h3 className="form_heading">Edit Account</h3>
            {showMessage && (
              <AlertMessage
                success={!failure}
                title={failure ? "Error" : "Success"}
                message={message}
              />
            )}
            <Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="vertical" gap={4}>
                <Form.Group className="mb-3" controlId="formPlaintextEmail">
                  <Stack direction="horizontal" gap={4}>
                    <Form.Label>Editing Account: </Form.Label>
                    <Form.Label className="text-muted">
                      {account?.email}
                    </Form.Label>
                  </Stack>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Stack direction="horizontal" gap={4}>
                    <Form.Label>Status:</Form.Label>
                    <Status
                      suspended={account.suspended || orgSuspended}
                      archived={account.archived}
                    />
                    <SvgBtn icon={infoBtn} onClick={() => setShowAudit(true)} />
                  </Stack>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Stack direction="horizontal" gap={4}>
                    <Form.Label>Suspend</Form.Label>
                    <Form.Check
                      disabled={
                        baseAccount?.archived || orgSuspended || orgArchived
                      }
                      type="switch"
                      name="suspended"
                      id="suspend-switch"
                      checked={account?.suspended || orgSuspended}
                      onChange={handleCheckChange}
                    />
                    <Form.Label>Archive</Form.Label>
                    <Form.Check
                      type="switch"
                      name="archived"
                      id="archive-switch"
                      checked={account?.archived}
                      onChange={handleCheckChange}
                      disabled={orgArchived}
                    />
                  </Stack>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Account Image</Form.Label>
                  <Form.Text className="text-muted">
                    Upload account image. This will be shown on event invites
                  </Form.Text>
                  <ImageSelector
                    imageUrl={account?.profilePictureUrl}
                    onChange={handleImageChange}
                    onDeleted={handleImageDelete}
                    altStr={initials}
                    disabled={disabled || baseAccount?.archived || orgArchived}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Text className="text-muted">
                    Enter the first name for this account
                  </Form.Text>
                  <Form.Control
                    size="lg"
                    name="firstName"
                    type="text"
                    placeholder="John"
                    value={account?.firstName}
                    onChange={handleChange}
                    disabled={disabled || baseAccount?.archived || orgArchived}
                    isInvalid={errorState.firstName && validated}
                    isValid={!errorState.firstName && validated}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="validationCustom01">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Text className="text-muted">
                    Enter the Last name for this account
                  </Form.Text>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Smith"
                    name="lastName"
                    value={account?.lastName}
                    onChange={handleChange}
                    disabled={disabled || baseAccount?.archived || orgArchived}
                    isInvalid={errorState.lastName && validated}
                    isValid={!errorState.lastName && validated}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Licence Type</Form.Label>
                  <Form.Text className="text-muted">
                    Select the licecne Type for this account
                  </Form.Text>
                  <Form.Select
                    size="lg"
                    name="licenceTypeId"
                    value={account?.licenceTypeId}
                    onChange={handleChange}
                    disabled={disabled || baseAccount?.archived || orgArchived}
                    isInvalid={errorState.licenceType && validated}
                    isValid={!errorState.licenceType && validated}
                  >
                    <LicenceOptionMapper
                      licenceTypes={licenceTypesState.licences}
                    />
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Organisation</Form.Label>
                  <Form.Text className="text-muted">
                    Select the organisation for this account
                  </Form.Text>
                  <Form.Select
                    size="lg"
                    name="organisationId"
                    value={account?.organisationId}
                    onChange={handleChange}
                    disabled={disabled || baseAccount?.archived || orgArchived}
                    isValid={validated}
                  >
                    <OrganisationOptionMapper
                      organisations={organisationsState.organisations}
                    />
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Licence Expiry Date</Form.Label>
                  <Form.Text className="text-muted">
                    Enter the date you want this account to be available until
                  </Form.Text>
                  <Form.Control
                    size="lg"
                    type="date"
                    name="licenceExpiry"
                    value={moment(account.licenceExpiry).format("yyyy-MM-DD")}
                    onChange={handleChange}
                    disabled={disabled || baseAccount?.archived || orgArchived}
                    isInvalid={errorState.licenseExpiry && validated}
                    isValid={!errorState.licenseExpiry && validated}
                  />
                </Form.Group>
                <Form.Group className="mb-3"></Form.Group>
              </Stack>
            </Form>
          </div>
        )}
      </div>
      <FormFooter primaryBtn={primaryBtn} secondaryBtn={secondaryBtn} />
      <ModalComponent
        actionHandler={() => navigate(cancelUrl!)}
        handleClose={handleClose}
        displayModal={showAlert}
        action={"Yes"}
        modalTitle={"Cancel Account Changes"}
        modalBody={
          "Your unsaved changes will be lost are you sure you want to cancel?"
        }
      />
      <AccountAuditWindow
        account={accountFromState}
        show={showAudit}
        handleClose={() => setShowAudit(false)}
      />
    </>
  );
};

export default EditAccountComponent;
