const OrganisationOptionMapper = ({ organisations }) => {
  return (
    <>
      <option disabled={false} key={undefined} value={""}>
        --Select an Organisation--
      </option>
      {organisations.map(
        ({ organisationName, organisationId, archived, suspended }) => (
          <option
            disabled={archived}
            key={organisationId}
            value={organisationId}
          >
            {`${organisationName} ${
              archived ? "(archived)" : suspended ? "(suspended)" : ""
            }`}
          </option>
        )
      )}
    </>
  );
};

export default OrganisationOptionMapper;
