import jwtDecode from "jwt-decode";
import moment from "moment";
import {
  authError,
  authRequest,
  authSuccess,
} from "redux/actions/auth/authActions";

let getAccessTokenSilently = null;

export function tokenValid(token: string) {
  const decoded = jwtDecode(token);
  return decoded && moment.utc(decoded["exp"] * 1000).isAfter(moment.utc());
}

export const tokenHelper = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func) => (getAccessTokenSilently = func),
};

export function fetchHandleGetAccessToken() {
  return async function fetchHandleGetAccessTokenThunk(dispatch) {
    try {
      dispatch(authRequest());
      if (tokenHelper.getAccessTokenSilently() !== null) {
        // @ts-ignore: Object is possibly 'null'.
        const token = await tokenHelper.getAccessTokenSilently()({});
        if (token) {
          dispatch(authSuccess(token));
        }
        return token;
      }
    } catch (err) {
      const errMessage = `Failed to get access token silently. Error: ${err}`;
      dispatch(authError(errMessage));
    }
  };
}
