import { ITag } from "interfaces/Tag/ITag";
import { ITagAction } from "interfaces/Tag/ITagAction";
import { ITagState } from "interfaces/Tag/ITagState";
import { TagActionTypes } from "redux/actions/tag/tagActionTypes";

const initialState: ITagState = {
  tags: [],
  loading: false,
};

export function tagReducers(
  state: ITagState = initialState,
  action: ITagAction
): ITagState {
  switch (action.type) {
    case TagActionTypes.GET_TAGS_REQUEST:
      return { ...state, loading: true, error: undefined };
    case TagActionTypes.GET_TAGS_SUCCESS: {
      const tags: ITag[] =
        action.data instanceof Array
          ? (action.data as ITag[])
          : [action.data as ITag];
      return { ...state, loading: false, error: undefined, tags: tags };
    }
    case TagActionTypes.GET_TAGS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}
