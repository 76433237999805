import IRootState from "interfaces/IRootState";

export const authStateSelector = (state: IRootState) => state.auth;

export const organisationsStateSelector = (state: IRootState) =>
  state.organisationState;

export const licensesStateSelector = (state: IRootState) => state.licenseState;

export const tagsStateSelector = (state: IRootState) => state.tagState;

export const accountsStateSelector = (state: IRootState) => state.accountsState;
