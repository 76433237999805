import { Outlet } from "react-router-dom";
import "../../assets/images/Edify_Logo_White.svg";

import NavbarHeader from "../../components/Headers/NavbarHeader";
import SidePanel from "../../components/SidePanel/SidePanel";

const Layout = (props) => {
  return (
    <>
      <main className="mainContainer">
        <div id="aSideContainer">
          <SidePanel />
        </div>
        <div id="contentContainer">
          <div id="header">
            <NavbarHeader />
          </div>
          <div id="content">
            <Outlet />
          </div>
        </div>
      </main>
    </>
  );
};

export default Layout;
