import { toCamelCase } from "helpers/Audit/AuditHelpers";
import { IOrganisation } from "interfaces/Organisation/IOrganisation";
import { useEffect, useState } from "react";
import Moment from "react-moment";

const OrganisationDetailsAuditEntry = ({
  responseBody,
  requestor,
  timestamp,
}) => {
  const [organisation, setOrganisation] = useState<IOrganisation>();

  useEffect(() => {
    try {
      const organisation = JSON.parse(responseBody, toCamelCase);
      if (organisation) {
        setOrganisation(organisation);
      }
    } catch (e) {
      console.log("Invalid json in audit response body");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {organisation && (
        <tr>
          <td>{organisation?.organisationName}</td>
          <td>{organisation?.email}</td>
          <td>{organisation?.description}</td>
          <td>{requestor}</td>
          <td>
            <Moment format="DD/MM/YYYY">{timestamp}</Moment>
          </td>
          <td>
            <Moment format="HH:mm:ss">{timestamp}</Moment>
          </td>
        </tr>
      )}
    </>
  );
};

export default OrganisationDetailsAuditEntry;
