
const DefaultThumbnailComponent = ({ size, text="" }) => {
    const radius = Math.floor(size / 2);
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
            <circle cx={radius} cy={radius} r={radius} fill="#cfcfcf" className="bi bi-circle-fill" />
            <text x="50%" y="50%" textAnchor="middle" fill="white" fontSize="1.6em" dy="0.3em">{text?.toUpperCase()}</text>
        </svg>
    )
};

const ThumbnailComponent = ({ organisationName, size, src = null }) => {
    return (
        src ? <img src={src} alt={`logo ${organisationName}`} width={size} height={size} className="org-thumbnail img-fluid" />
            : <DefaultThumbnailComponent size={size} text={organisationName.slice(0,1)}/>
    )
}

export default ThumbnailComponent;
