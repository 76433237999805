export enum OrganisationActionTypes {
  GET_ORGANISATIONS_REQUEST = "GET_ORGANISATIONS_REQUEST",
  GET_ORGANISATIONS_SUCCESS = "GET_ORGANISATIONS_SUCCESS",
  GET_ORGANISATIONS_ERROR = "GET_ORGANISATIONS_ERROR",
  GET_ORGANISATION_SUCCESS = "GET_ORGANISATION_SUCCESS",
  GET_ORGANISATION_ERROR = "GET_ORGANISATION_ERROR",
  CREATE_ORGANISATION_SUCCESS = "CREATE_ORGANISATION_SUCCESS",
  CREATE_ORGANISATION_FAILED = "CREATE_ORGANISATION_FAILED",
  UPDATE_ORGANISATION_SUCCESS = "UPDATE_ORGANISATION_SUCCESS",
  DELETE_ORGANISATION_SUCCESS = "DELETE_ORGANISATION_SUCCESS",
  UPDATE_ORGANISATION_FAILED = "UPDATE_ORGANISATION_FAILED",
  UPDATE_ORGANISATION_REQUEST = "UPDATE_ORGANISATION_REQUEST",
}
