import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import Layout from "../Layout/Layout";
import CreateAccountComponent from "components/Account/CreateAccount";
import ProtectedComponent from "containers/Auth/ProtectedComponent";
import CreateOrganisationComponent from "components/Organisation/CreateOrganisation";
import EditAccountComponent from "components/Account/EditAccount";
import ErrorComponent from "containers/Error/ErrorComponent";
import LandingComponent from "components/Landing/LandingComponent";
import SuccessPageComponent from "components/Organisation/SuccessPage";
import EditOrganisationComponent from "components/Organisation/EditOrganisation";
import OrganisationsAccounts from "containers/OrganisationAccounts/OrganisationsAccounts";
import Organisation from "containers/Organisation/Organisation";
import { TokenContext } from "helpers/constants/Contexts";
import { authInit } from "redux/actions/auth/authActions";
import { authStateSelector } from "redux/states/stateSelectors";
import { refreshStates } from "redux/states/localStorage";
import {
  fetchHandleGetAccessToken,
  tokenHelper,
} from "helpers/Auth/AuthHelpers";
import Download from "components/Loaders/Download";

const App = () => {
  //#region constants
  const errorPageUri = "/error";
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector(authStateSelector);
  const { isAuthenticated, error, getAccessTokenSilently } = useAuth0();
  tokenHelper.setAccessTokenSilently(getAccessTokenSilently);
  //#endregion

  //#region useStates
  const [accessToken, setAccessToken] = useState("");
  //#endregion

  //#region useEffects
  useEffect(() => {
    if (error) {
      navigate(errorPageUri);
      return;
    }
    if (isAuthenticated) {
      initialize();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, error]);
  //#endregion

  //#region functions
  const initialize = async () => {
    dispatch(authInit(isAuthenticated));
    var result: Promise<string> = dispatch(fetchHandleGetAccessToken());
    result.then((token) => {
      if (authState.token !== token) {
        //refresh states if new token is issued
        refreshStates(dispatch, token);
      }
      setAccessToken(token);
    });
  };
  //#endregion

  return (
    <TokenContext.Provider value={accessToken}>
      <Routes>
        <Route path="/" element={<LandingComponent />} />

        <Route path="/error" element={<ErrorComponent />} />
        <Route
          path="/migration-report/:reportName"
          element={<ProtectedComponent component={Download} />}
        />

        <Route
          path="/accounts"
          element={<ProtectedComponent component={Layout} />}
        >
          <Route path="add" element={<CreateAccountComponent />}>
            <Route path="success" element={<CreateAccountComponent />} />
          </Route>

          <Route path="edit/:accountId" element={<EditAccountComponent />} />
        </Route>

        <Route
          path="/organisations"
          element={<ProtectedComponent component={Layout} />}
        >
          <Route index element={<OrganisationsAccounts />} />
          <Route path=":orgId" element={<Organisation />} />
          <Route path="add" element={<CreateOrganisationComponent />} />
          <Route path="edit/:orgId" element={<EditOrganisationComponent />} />
          <Route
            path=":orgId/accounts/add"
            element={<CreateAccountComponent />}
          >
            <Route path="success" element={<CreateAccountComponent />} />
          </Route>
          <Route
            path=":orgId/accounts/edit/:accountId"
            element={<EditAccountComponent />}
          />
          <Route path="success" element={<SuccessPageComponent />} />
        </Route>
      </Routes>
    </TokenContext.Provider>
  );
};

export default App;
