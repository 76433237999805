import { Component } from "react";
import logo from "../../assets/images/Edify_Logo_Blue.svg";

class Loader extends Component {
  render() {
    return (
      <div data-testid="loader" className="fp_loading">
        <img alt="Edify" src={logo} height="68"></img>
        <div className="loader mt-3"></div>
      </div>
    );
  }
}

export default Loader;
