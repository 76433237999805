import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import AlertMessage from "components/Message/AlertMessage";
import { useDispatch, useSelector } from "react-redux";
import FormFooter from "components/Footers/FormFooter";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { ICreateAccountRequest } from "interfaces/Account/ICreateAccountRequest";
import { useNavigate } from "react-router-dom";
import { Form, Stack } from "react-bootstrap";
import Loader from "components/Loaders/Loader";
import LicenceOptionMapper from "components/Mappers/LicenceOptionMapper";
import {
  defaultAccountErrorState,
  defaultCreateAccountRequest,
} from "helpers/constants/AccountConstants";
import { fetchHandleCreateAccount } from "helpers/Account/AccountHelpers";
import { findOrganisation } from "helpers/Organisations/OrganisationHelpers";
import OrganisationOptionMapper from "components/Mappers/OrganisationOptionMapper";
import { IButtonProps } from "interfaces/General/IButtonProps";
import ModalComponent from "components/Modal/ModalComponent";
import { TokenContext } from "helpers/constants/Contexts";
import { IAccount } from "interfaces/Account/IAccount";
import { ILicenceState } from "interfaces/Licence/ILicenseState";
import { IOrganisationsState } from "interfaces/Organisation/IOrganisationsState";
import {
  licensesStateSelector,
  organisationsStateSelector,
} from "redux/states/stateSelectors";

const CreateAccountComponent = () => {
  //#region useSelectors
  const licenceTypesState: ILicenceState = useSelector(licensesStateSelector);
  const organisationsState: IOrganisationsState = useSelector(
    organisationsStateSelector
  );
  //#endregion

  //#region constants
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const successMessageEmail = searchParams.has("email")
    ? searchParams.get("email")
    : undefined;
  const accessToken = useContext(TokenContext);
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const { handleSubmit } = useForm();
  const navigate = useNavigate();
  //#endregion

  //#region useStates
  const [disableOrganisationCombobox, setDisableOrganisationCombobox] =
    useState(false);
  const [cancelUrl, setCancelUrl] = useState("/organisations");
  const [account, setAccount] = useState(defaultCreateAccountRequest);
  const [validated, setValidated] = useState(false);
  const [errorState, setErrorState] = useState(defaultAccountErrorState);
  const [disabled, setDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState<string>();
  const [failure, setFailure] = useState(false);
  //#endregion

  const primaryBtn: IButtonProps = {
    text: "Submit",
    onClick: () => onSubmit(),
    disabled: disabled,
  };
  const secondaryBtn: IButtonProps = {
    text: "Cancel",
    onClick: () => onCancel(),
    disabled: disabled,
  };

  //#region useEffects
  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (orgId) {
      setCancelUrl(`/organisations/${orgId}`);
      const org = findOrganisation(orgId, organisationsState.organisations);
      if (org) {
        setAccount({
          ...account,
          organisationId: org.organisationId,
        });
        setDisableOrganisationCombobox(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  useEffect(() => {
    if (validated) {
      displayMessage("Please fill out the required fields.", false);
      window.scrollTo(0, 0);
    }
  }, [validated]);

  useEffect(() => {
    if (successMessageEmail) {
      displayMessage(
        `Successfully Created Account for ${successMessageEmail}`,
        true
      );
    }
  }, [successMessageEmail]);
  //#endregion

  //#region functions
  const handleChange = (event) => {
    setAccount({ ...account, [event.target.name]: event.target.value });
  };

  const validate = () => {
    setErrorState({
      ...errorState,
      firstName: account.firstName.trim().length === 0,
      lastName: account.lastName.trim().length === 0,
      email: !/\S+@\S+\.\S+/.test(account.email),
      licenceType: account.licenceTypeId.trim().length === 0,
      licenseExpiry:
        account.licenceExpiry.trim().length === 0 ||
        moment.utc(account.licenceExpiry).isBefore(moment.utc()),
    });
  };

  const onSubmit = () => {
    window.scrollTo(0, 0);
    if (
      !errorState.firstName &&
      !errorState.lastName &&
      !errorState.email &&
      !errorState.licenceType &&
      !errorState.licenseExpiry
    ) {
      sendCreateAccountRequest(account);
    }
    setValidated(true);
  };

  const onCreateAccountSuccess = (account: IAccount) => {
    //navigate to create with success message
    navigate(
      `${location.pathname.replace(
        "/success",
        ""
      )}/success?email=${encodeURIComponent(account.email)}`
    );
  };

  const onCreateAccountError = (message: string) => {
    setDisabled(false);
    displayMessage(`Could not create account for ${account.email}`, false);
    window.scrollTo(0, 0);
  };

  const displayMessage = (message: string, success: boolean) => {
    setMessage(message);
    setFailure(!success);
    setShowMessage(true);
  };

  const sendCreateAccountRequest = async (account: ICreateAccountRequest) => {
    setDisabled(true);
    dispatch(
      fetchHandleCreateAccount(
        accessToken,
        account,
        (account) => onCreateAccountSuccess(account),
        (message) => onCreateAccountError(message)
      )
    );
  };

  const onCancel = () => {
    if (
      JSON.stringify(account) !== JSON.stringify(defaultCreateAccountRequest)
    ) {
      setShowAlert(true);
    } else {
      navigate(cancelUrl);
      return;
    }
  };

  const handleClose = () => {
    setShowAlert(false);
  };
  //#endregion

  return (
    <>
      <div className="form-page">
        {disabled ? (
          <Loader />
        ) : (
          <div className="vstack gap-4 col-md-5 mx-auto">
            <h3 className="form_heading">Account Details</h3>
            {showMessage && (
              <AlertMessage
                success={!failure}
                title={failure ? "Error" : "Success"}
                message={message}
              />
            )}
            <Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="vertical" gap={4}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Text className="text-muted">
                    Enter the first name for this account
                  </Form.Text>
                  <Form.Control
                    size="lg"
                    name="firstName"
                    type="text"
                    placeholder="John"
                    value={account.firstName}
                    onChange={handleChange}
                    disabled={disabled}
                    isInvalid={errorState.firstName && validated}
                    isValid={!errorState.firstName && validated}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="validationCustom01">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Text className="text-muted">
                    Enter the Last name for this account
                  </Form.Text>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Smith"
                    name="lastName"
                    value={account.lastName}
                    onChange={handleChange}
                    disabled={disabled}
                    isInvalid={errorState.lastName && validated}
                    isValid={!errorState.lastName && validated}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Text className="text-muted">
                    Enter the email address for this account
                  </Form.Text>
                  <Form.Control
                    size="lg"
                    type="email"
                    placeholder="john.smith@email.com"
                    name="email"
                    value={account.email}
                    onChange={handleChange}
                    disabled={disabled}
                    isInvalid={errorState.email && validated}
                    isValid={!errorState.email && validated}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Licence Type</Form.Label>
                  <Form.Text className="text-muted">
                    Select the licecne Type for this account
                  </Form.Text>
                  <Form.Select
                    size="lg"
                    name="licenceTypeId"
                    value={account.licenceTypeId}
                    onChange={handleChange}
                    disabled={disabled}
                    isInvalid={errorState.licenceType && validated}
                    isValid={!errorState.licenceType && validated}
                  >
                    <LicenceOptionMapper
                      licenceTypes={licenceTypesState.licences}
                    />
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Organisation</Form.Label>
                  <Form.Text className="text-muted">
                    Select the organisation for this account
                  </Form.Text>
                  <Form.Select
                    size="lg"
                    name="organisationId"
                    value={account.organisationId}
                    onChange={handleChange}
                    disabled={disabled || disableOrganisationCombobox}
                    isValid={validated}
                  >
                    <OrganisationOptionMapper
                      organisations={organisationsState.organisations}
                    />
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Licence Expiry Date</Form.Label>
                  <Form.Text className="text-muted">
                    Enter the date you want this account to be available until
                  </Form.Text>
                  <Form.Control
                    size="lg"
                    type="date"
                    name="licenceExpiry"
                    value={account.licenceExpiry}
                    onChange={handleChange}
                    disabled={disabled}
                    isInvalid={errorState.licenseExpiry && validated}
                    isValid={!errorState.licenseExpiry && validated}
                  />
                </Form.Group>
              </Stack>
            </Form>
          </div>
        )}
      </div>
      <FormFooter primaryBtn={primaryBtn} secondaryBtn={secondaryBtn} />
      <ModalComponent
        actionHandler={() => navigate(cancelUrl!)}
        handleClose={handleClose}
        displayModal={showAlert}
        action={"Yes"}
        modalTitle={"Cancel Account Changes"}
        modalBody={
          "Your unsaved changes will be lost are you sure you want to cancel?"
        }
      />
    </>
  );
};

export default CreateAccountComponent;
