import TagComponent from "components/Tags/TagComponent";
import { generateMappingKey } from "helpers/GeneralHelpers";

const TagComponentMapper = ({
  tags,
  onDelete,
  showCloseIcon,
  disabled = false,
}) => {
  return (
    <>
      {!disabled &&
        tags?.map((tag) => {
          return (
            <TagComponent
              key={generateMappingKey(tag)}
              HandleTagDeletion={onDelete}
              Description={tag}
              ShowCloseIcon={showCloseIcon}
              BackgroundColour={"#141b4d"}
            />
          );
        })}
    </>
  );
};

export default TagComponentMapper;
