import { AxiosResponse } from "axios";
import EdifyPrivateService from "./EdifyPrivateService";
import { IAccount } from "../interfaces/Account/IAccount";
import { ICreateAccountRequest } from "../interfaces/Account/ICreateAccountRequest";
import { IGetAccountsRequest } from "../interfaces/Account/IGetAccountsRequest";
import { IServiceResponse } from "../interfaces/General/IServiceResponse";
import { IUpdateAccountRequest } from "../interfaces/Account/IUpdateAccountRequest";

class AccountService extends EdifyPrivateService {
  createAccount(
    accountParams: ICreateAccountRequest
  ): Promise<AxiosResponse<IServiceResponse<IAccount>>> {
    return this.http.post("v1/account", {
      firstName: accountParams.firstName,
      lastName: accountParams.lastName,
      email: accountParams.email,
      licenceTypeId: accountParams.licenceTypeId,
      organisationId: accountParams.organisationId,
      licenceExpiry: accountParams.licenceExpiry,
    });
  }

  getAccounts(
    accountParams: IGetAccountsRequest
  ): Promise<AxiosResponse<IServiceResponse<IAccount[]>>> {
    return this.http.get("v1/accounts", {
      params: {
        organisationId: accountParams.OrganisationId,
        licenceTypeId: accountParams.LicenceTypeId,
        sortBy: accountParams.SortBy,
        searchValue: accountParams.SearchValue,
      },
    });
  }

  updateAccount(
    accountId: string,
    accountParams: IUpdateAccountRequest
  ): Promise<AxiosResponse<IServiceResponse<IAccount>>> {
    return this.http.patch(`v1/account/${accountId}`, {
      firstName: accountParams.firstName,
      lastName: accountParams.lastName,
      profilePictureUrl: accountParams.profilePictureUrl,
      suspended: accountParams.suspended,
      archived: accountParams.archived,
      licenceTypeId: accountParams.licenceTypeId,
      licenceExpiry: accountParams.licenceExpiry,
      organisationId: accountParams.organisationId,
    });
  }

  getUploadToken(
    auth0Id: string,
    fileName: string
  ): Promise<AxiosResponse<IServiceResponse<string>>> {
    return this.http.post(`v1/account/${auth0Id}/picture`, {
      fileName: fileName,
    });
  }
}

export default AccountService;
