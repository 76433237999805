import { Action } from "redux";
import { AccountActionTypes } from "redux/actions/account/accountActionTypes";
import { IAccount } from "./IAccount";
import { ICreateAccountRequest } from "./ICreateAccountRequest";
import { IGetAccountsRequest } from "./IGetAccountsRequest";
import { IUpdateAccountRequest } from "./IUpdateAccountRequest";

export interface IAccountAction extends Action<AccountActionTypes> {
  data:
    | IAccount
    | IAccount[]
    | ICreateAccountRequest
    | IGetAccountsRequest
    | IUpdateAccountRequest
    | string
    | null;
}

export function createAccountRequest(
  account: ICreateAccountRequest
): IAccountAction {
  return {
    type: AccountActionTypes.CREATE_ACCOUNT_REQUEST,
    data: account,
  };
}

export function createAccountSuccess(account: IAccount): IAccountAction {
  return {
    type: AccountActionTypes.CREATE_ACCOUNT_SUCCESS,
    data: account,
  };
}

export function createAccountError(): IAccountAction {
  return {
    type: AccountActionTypes.CREATE_ACCOUNT_ERROR,
    data: null,
  };
}

export function getAccountsRequest(
  params: IGetAccountsRequest
): IAccountAction {
  return {
    type: AccountActionTypes.GET_ACCOUNTS_REQUEST,
    data: params,
  };
}

export function getAccountsSuccess(account: IAccount[]): IAccountAction {
  return {
    type: AccountActionTypes.GET_ACCOUNTS_SUCCESS,
    data: account,
  };
}

export function getOrganisationAccountsSuccess(
  account: IAccount[]
): IAccountAction {
  return {
    type: AccountActionTypes.GET_ORG_ACCOUNTS_SUCCESS,
    data: account,
  };
}

export function getAccountsError(error: string): IAccountAction {
  return {
    type: AccountActionTypes.GET_ACCOUNTS_ERROR,
    data: error,
  };
}

export function deleteAccountRequest(accountId: string): IAccountAction {
  return {
    type: AccountActionTypes.DELETE_ACCOUNT_REQUEST,
    data: accountId,
  };
}

export function deleteAccountSuccess(account: IAccount): IAccountAction {
  return {
    type: AccountActionTypes.DELETE_ACCOUNT_SUCCESS,
    data: account,
  };
}

export function deleteAccountError(error: string): IAccountAction {
  return {
    type: AccountActionTypes.DELETE_ACCOUNT_ERROR,
    data: error,
  };
}

export function updateAccountRequest(
  account: IUpdateAccountRequest
): IAccountAction {
  return {
    type: AccountActionTypes.UPDATE_ACCOUNT_REQUEST,
    data: account,
  };
}

export function updateAccountSuccess(account: IAccount): IAccountAction {
  return {
    type: AccountActionTypes.UPDATE_ACCOUNT_SUCCESS,
    data: account,
  };
}

export function updateAccountError(): IAccountAction {
  return {
    type: AccountActionTypes.UPDATE_ACCOUNT_ERROR,
    data: null,
  };
}

export function userUploadRequestStarted(fileName: string): IAccountAction {
  return {
    type: AccountActionTypes.GET_UPLOAD_TOKEN_STARTED,
    data: fileName,
  };
}

export function userUploadRequestSuccess(uploadToken: string): IAccountAction {
  return {
    type: AccountActionTypes.GET_UPLOAD_TOKEN_SUCCESS,
    data: uploadToken,
  };
}

export function userUploadRequestError(error: string): IAccountAction {
  return {
    type: AccountActionTypes.GET_UPLOAD_TOKEN_ERROR,
    data: error,
  };
}
