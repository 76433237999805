import { toCamelCase } from "helpers/Audit/AuditHelpers";
import { ILicence } from "interfaces/Licence/ILicence";
import { useEffect, useState } from "react";
import Moment from "react-moment";

const AccountLicenceAuditEntry = ({ responseBody, requestor, timestamp }) => {
  const [licence, setLicence] = useState<ILicence>();

  useEffect(() => {
    try {
      const account = JSON.parse(responseBody, toCamelCase);
      if (account) {
        setLicence(account.licence);
      }
    } catch (e) {
      console.log("Invalid json in audit response body");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {licence && (
        <tr>
          <td>{licence?.licenceName}</td>
          <td>
            <Moment format="DD/MM/YYYY">{licence?.nextInvoiceDate}</Moment>
          </td>
          <td>{requestor}</td>
          <td>
            <Moment format="DD/MM/YYYY">{timestamp}</Moment>
          </td>
          <td>
            <Moment format="HH:mm:ss">{timestamp}</Moment>
          </td>
        </tr>
      )}
    </>
  );
};

export default AccountLicenceAuditEntry;
