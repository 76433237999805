import axios, { AxiosResponse } from "axios";
import AccountService from "services/AccountService";
import { IFile } from "interfaces/General/IFile";
import { IServiceResponse } from "interfaces/General/IServiceResponse";

export function putData(data: any, uploadUrl: string) {
  return new Promise<AxiosResponse>((resolve, reject) => {
    axios
      .put(uploadUrl, data, {
        headers: { "x-ms-blob-type": "BlockBlob" },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(`Failed to upload image: ${error}`);
      });
  });
}

export function readFile(file: IFile) {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsArrayBuffer(file.path);
  });
}

export function getUploadToken(
  accessToken,
  accountId: string,
  fileName: string
) {
  return new Promise<string>(async (resolve, reject) => {
    const accountService = new AccountService(
      process.env.REACT_APP_API_BASE_URL!,
      accessToken
    );
    const response = await accountService.getUploadToken(accountId, fileName);
    const serviceResponse: IServiceResponse<string> = response.data;
    if (serviceResponse.success) {
      resolve(serviceResponse.data);
    } else {
      reject(`Failed to get upload token: ${serviceResponse.message}`);
    }
  });
}
