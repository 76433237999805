import SidePanelMenuItem from "./SidePanelMenuItem";
import EdifyLogo from "../../assets/images/Edify_Logo_White.svg";

const SidePanel = (props) => {
  return (
    <>
      <aside id="sidebar" className="sidebar">
        <div className="logo_div">
          <img
            src={EdifyLogo}
            alt="Edify logo"
            className="d-inline-block align-middle"
          />
          <a className="logo__link navbar-brand" href="/">
            Internal CMS
          </a>
        </div>
        <div className="sidebar__holder">
          <ul className="nav flex-column">
            <SidePanelMenuItem description="Home" />
            <SidePanelMenuItem description="Organisation & Accounts" />
          </ul>
        </div>
      </aside>
    </>
  );
};

export default SidePanel;
