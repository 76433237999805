import { AxiosResponse } from "axios";
import EdifyPrivateService from "./EdifyPrivateService";
import { IServiceResponse } from "../interfaces/General/IServiceResponse";
import { IGetAuditLogs } from "interfaces/Audit/IGetAuditLogs";
import { IAuditLog } from "interfaces/Audit/IAuditLog";

class AuditService extends EdifyPrivateService {
  getAuditLogs(
    entityId: string,
    auditParams: IGetAuditLogs
  ): Promise<AxiosResponse<IServiceResponse<IAuditLog[]>>> {
    return this.http.get(`v1/audit/${entityId}`, {
      params: {
        operationCategories: auditParams.operationCategories,
        requestorId: auditParams.requestorId,
      },
    });
  }
}

export default AuditService;
