import { ILicenceAction } from "interfaces/Licence/ILicenceAction";
import { ILicenceState } from "interfaces/Licence/ILicenseState";
import { LicenceActionTypes } from "redux/actions/licence/licenceActionTypes";

const initialState: ILicenceState = {
  licences: [],
  loading: false,
};

export function licenceReducer(
  state: ILicenceState = initialState,
  action: ILicenceAction
): ILicenceState {
  switch (action.type) {
    case LicenceActionTypes.GET_LICENCES_REQUEST:
      return { ...state, loading: true, error: undefined };

    case LicenceActionTypes.GET_LICENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        licences: action.data,
        error: undefined,
      };

    case LicenceActionTypes.GET_LICENCES_ERROR:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
}
