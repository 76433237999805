import { Stack, Form, Row, Button } from "react-bootstrap";
import { SortOptions } from "helpers/enums/SortOptions";
import { TabOptions } from "helpers/enums/TabOptions";
import AlertMessage from "components/Message/AlertMessage";
import {
  filterOrganisationsByString,
  filterOrganisationsByTags,
  sortOrganisations,
} from "helpers/Organisations/OrganisationHelpers";
import { useState, useEffect, useContext } from "react";
import Loader from "../Loaders/Loader";
import OrganisationItemComponent from "./OrganisationListItem";
import TagOptionMapper from "components/Mappers/TagOptionMapper";
import { IOrganisationsState } from "interfaces/Organisation/IOrganisationsState";
import { useDispatch, useSelector } from "react-redux";
import {
  organisationsStateSelector,
  tagsStateSelector,
} from "redux/states/stateSelectors";
import { IOrganisation } from "interfaces/Organisation/IOrganisation";
import { ITagState } from "interfaces/Tag/ITagState";
import { generateMappingKey } from "helpers/GeneralHelpers";
import { refreshStates } from "redux/states/localStorage";
import { TokenContext } from "helpers/constants/Contexts";

const ListOrganisationComponent = ({ activeTab }) => {
  //#region useStates
  const accessToken = useContext(TokenContext);
  const dispatch = useDispatch();
  const [organisationList, setOrganisationList] = useState<IOrganisation[]>();
  const [tag, setTag] = useState(
    sessionStorage.getItem("organisationTag_filter") !== null || undefined
      ? sessionStorage.getItem("organisationTag_filter")
      : "all"
  );
  const [sortBy, setSortBy] = useState(
    sessionStorage.getItem("organisationSortBy_filter") !== null || undefined
      ? sessionStorage.getItem("organisationSortBy_filter")
      : SortOptions.Ascending
  );
  const [searchValue, setSearchValue] = useState(
    sessionStorage.getItem("organisationSearchValue_filter") !== null ||
      undefined
      ? sessionStorage.getItem("organisationSearchValue_filter")
      : ""
  );
  const organisationState: IOrganisationsState = useSelector(
    organisationsStateSelector
  );
  const tagsState: ITagState = useSelector(tagsStateSelector);
  //#endregion
  useEffect(() => {
    if (!organisationState.loading) {
      setOrganisationList(organisationState.organisations);
      applyFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationState.loading]);

  //#region useEffects
  useEffect(() => {
    if (activeTab === TabOptions.UserAccounts) {
      resetFilters();
    }
  }, [activeTab]);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, tag, sortBy]);

  const applyFilters = () => {
    let filteredOrganisations = filterOrganisationsByString(
      searchValue!,
      organisationState.organisations
    );
    filteredOrganisations = filterOrganisationsByTags(
      tag!,
      filteredOrganisations
    );
    setOrganisationList(sortOrganisations(filteredOrganisations, sortBy!));
    sessionStorage.setItem("organisationSearchValue_filter", searchValue!);
    sessionStorage.setItem("organisationTag_filter", tag!);
    sessionStorage.setItem("organisationSortBy_filter", sortBy!);
  };
  //#endregion

  //#region functions
  const onSearchUpdated = (event) => {
    setSearchValue(event.target.value);
  };

  const onTagUpdated = (tag: string) => {
    setTag(tag);
  };

  const onSortByUpdated = (event) => {
    setSortBy(event.target.value);
  };

  const resetFilters = () => {
    setSearchValue("");
    setSortBy(SortOptions.Ascending);
    setTag("all");
  };
  //#endregion

  return (
    <>
      <div className="tab_content">
        <Stack direction="vertical" gap={4}>
          <Stack direction="horizontal">
            <h3 className="list_heading">Organisations</h3>
          </Stack>
          <Stack direction="horizontal" gap={4}>
            <Button variant="link" onClick={resetFilters}>
              Reset Filters
            </Button>
            <Button
              variant="link"
              onClick={() => refreshStates(dispatch, accessToken)}
            >
              Refresh
            </Button>
          </Stack>
          <Form>
            <Stack direction="horizontal" gap={5}>
              <Form.Group as={Row}>
                <Stack direction="horizontal" gap={3}>
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Dynamic Search"
                    name="search"
                    value={searchValue!}
                    onChange={onSearchUpdated}
                    disabled={
                      organisationState.loading ||
                      organisationState.error !== undefined
                    }
                  />
                </Stack>
              </Form.Group>
              <Form.Group className="ms-auto">
                <Stack direction="horizontal" gap={3}>
                  <Form.Label>Tag</Form.Label>
                  <Form.Select
                    size="lg"
                    name="tags"
                    placeholder="Select"
                    onChange={(e) => onTagUpdated(e.target.value)}
                    value={tag!}
                    disabled={
                      organisationState.loading ||
                      organisationState.error !== undefined
                    }
                  >
                    <option value="all">All</option>
                    <TagOptionMapper tags={tagsState.tags} />
                  </Form.Select>
                </Stack>
              </Form.Group>
              <Form.Group>
                <Stack direction="horizontal" gap={3}>
                  <Form.Label>Sort&nbsp;By</Form.Label>
                  <Form.Select
                    size="lg"
                    name="sortBy"
                    placeholder="Select"
                    value={sortBy!}
                    onChange={onSortByUpdated}
                    disabled={
                      organisationState.loading ||
                      organisationState.error !== undefined
                    }
                  >
                    <option value="ascending">Ascending</option>
                    <option value="descending">Descending</option>
                  </Form.Select>
                </Stack>
              </Form.Group>
            </Stack>
          </Form>
          <Stack direction="horizontal" gap={4}></Stack>
          {organisationState.loading ? (
            <Loader />
          ) : organisationState.error ? (
            <AlertMessage
              success={false}
              title={"Error"}
              message={organisationState.error}
            />
          ) : (
            organisationList?.map((organisation) => {
              return (
                <div key={generateMappingKey(organisation.organisationId)}>
                  <OrganisationItemComponent
                    organisation={organisation}
                    onTagSelected={onTagUpdated}
                    searchValue={searchValue}
                  />
                </div>
              );
            })
          )}
        </Stack>
      </div>
    </>
  );
};

export default ListOrganisationComponent;
