import { fetchHandleGetAccounts } from "helpers/Account/AccountHelpers";
import { defaultGetAccountsRequest } from "helpers/constants/AccountConstants";
import { fetchHandleGetLicenceTypes } from "helpers/Licence/LicenceHelpers";
import { fetchHandleGetOrganisations } from "helpers/Organisations/OrganisationHelpers";
import { fetchHandleGetTags } from "helpers/Tag/TagHelpers";

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (error) {
    console.log(error);
  }
};

export const refreshStates = (dispatch, token) => {
  dispatch(fetchHandleGetOrganisations(token));
  dispatch(fetchHandleGetAccounts(token, defaultGetAccountsRequest));
  dispatch(fetchHandleGetTags(token));
  dispatch(fetchHandleGetLicenceTypes(token));
};
