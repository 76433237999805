import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IOrganisation } from "../../interfaces/Organisation/IOrganisation";
import FormFooter from "../Footers/FormFooter";
import { useForm } from "react-hook-form";
import AlertMessage from "../Message/AlertMessage";
import { useNavigate, useParams } from "react-router-dom";
import { IOrganisationsState } from "../../interfaces/Organisation/IOrganisationsState";
import Loader from "../Loaders/Loader";
import { IButtonProps } from "interfaces/General/IButtonProps";
import {
  defaultOrganisationErrorState,
  defaultUpdateOrganisationRequest,
} from "helpers/constants/OrganisationConstants";
import {
  fetchHandleDeleteOrganisation,
  fetchHandleUpdateOrganisation,
} from "helpers/Organisations/OrganisationHelpers";
import TagInput from "components/Tags/TagInput";
import { TokenContext } from "helpers/constants/Contexts";
import { Form, Stack } from "react-bootstrap";
import { IFile } from "interfaces/General/IFile";
import { getUploadToken } from "helpers/Files/FileHelpers";
import ModalComponent from "components/Modal/ModalComponent";
import { IUpdateOrganisationRequestObject } from "interfaces/Organisation/IUpdateOrganisationRequest";
import { organisationsStateSelector } from "redux/states/stateSelectors";
import Status from "components/General/Status";
import { fetchHandleGetAccounts } from "helpers/Account/AccountHelpers";
import { IGetAccountsRequest } from "interfaces/Account/IGetAccountsRequest";

const EditOrganisationComponent = () => {
  //#region constants
  const accessToken = useContext(TokenContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit } = useForm();
  const { orgId } = useParams();
  const organisationState: IOrganisationsState = useSelector(
    organisationsStateSelector
  );
  //#endregion

  //#region useStates
  const [organisation, setOrganisation] =
    useState<IUpdateOrganisationRequestObject>(
      defaultUpdateOrganisationRequest
    );
  const [organisationChanges, setOrganisationChanges] =
    useState<IUpdateOrganisationRequestObject>();
  const [baseOrganisation, setBaseOrganisation] =
    useState<IUpdateOrganisationRequestObject>();
  const [imageToUpload] = useState<IFile | null>();
  //COMMENTED OUT UNTIL FEATURE PRESENT
  //const [initials, setInitials] = useState<string>();
  const [validated, setValidated] = useState(false);
  const [errorState, setErrorState] = useState(defaultOrganisationErrorState);
  const [disabled, setDisabled] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState<string>();
  const [failure, setFailure] = useState(false);
  const [cancelUrl, setCancelUrl] = useState("/organisations");
  const [showAlert, setShowAlert] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  //#endregion
  const primaryBtn: IButtonProps = {
    text: "Save Changes",
    onClick: () => handleSubmit(onSubmit)(),
    disabled: disabled || !changesMade,
  };
  const secondaryBtn: IButtonProps = {
    text: "Cancel",
    onClick: () => onCancel(),
    disabled: disabled,
  };

  //#region useEffects
  useEffect(() => {
    validate();
    if (JSON.stringify(organisation) !== JSON.stringify(baseOrganisation)) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  useEffect(() => {
    if (orgId) {
      setCancelUrl(`/organisations/${orgId}`);
    }
    let org: IOrganisation = organisationState.organisations.filter(
      (o) => o.organisationId === orgId
    )[0];

    if (!org) {
      navigate("/organisations");
      return;
    }

    let formOrg: IUpdateOrganisationRequestObject = {
      organisationName: org.organisationName,
      organisationEmail: org.email,
      organisationDescription: org.description,
      organisationPictureUrl: org.organisationPictureUrl,
      organisationTags: org.tags,
      suspended: org.suspended,
      archived: org.archived,
    };
    setOrganisation(formOrg);
    setBaseOrganisation(formOrg);
    //COMMENTED OUT UNTIL FEATURE PRESENT
    //setInitials(`${Array.from(org.organisationName)[0]}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (validated) {
      setMessage("Please fill out the required fields.");
      setFailure(true);
      setShowMessage(true);
      window.scrollTo(0, 0);
    }
  }, [validated]);
  //#endregion

  //#region functions
  const handleChange = (event) => {
    setOrganisation({
      ...organisation,
      [event.target.name]: event.target.value,
    });
    setOrganisationChanges({
      ...organisationChanges,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckChange = (event) => {
    setOrganisation({
      ...organisation!,
      [event.target.name]: event.target.checked,
    });
    setOrganisationChanges({
      ...organisationChanges,
      [event.target.name]: event.target.checked,
    });
  };

  const onTagsUpdated = (updatedTags: string[]) => {
    setOrganisation({
      ...organisation,
      organisationTags: updatedTags,
    });
    setOrganisationChanges({
      ...organisationChanges,
      organisationTags: updatedTags,
    });
  };

  //COMMENTED OUT UNTIL FEATURE PRESENT
  // const handleImageChange = (event) => {
  //   const file: IFile = {
  //     path: event.target.files[0],
  //     name: event.target.files[0].name,
  //   };
  //   setImageToUpload(file);
  //   setOrganisation({
  //     ...organisation,
  //     organisationPictureUrl: URL.createObjectURL(event.target.files[0]),
  //   });
  // };
  //COMMENTED OUT UNTIL FEATURE PRESENT
  // const handleImageDelete = () => {
  //   setOrganisation({
  //     ...organisation,
  //     organisationPictureUrl: "",
  //   });
  //   setImageToUpload(null);
  // };

  const onSubmit = () => {
    window.scrollTo(0, 0);
    if (organisationChanges) {
      if (organisationChanges.archived) {
        deleteOrganisation();
      } else if (
        !errorState.organisationName &&
        !errorState.organisationEmail
      ) {
        updateOrganisation(organisationChanges);
      }
      setValidated(true);
    }
  };

  const updateOrganisation = async (
    organisation: IUpdateOrganisationRequestObject
  ) => {
    setValidated(false);
    setDisabled(true);
    try {
      if (imageToUpload) {
        const uploadUrl = await getUploadToken(
          accessToken,
          orgId!,
          imageToUpload.name
        );
        //COMMENTED OUT UNTIL FEATURE PRESENT
        //const fileData = await readFile(imageToUpload);
        organisation.organisationPictureUrl = uploadUrl.split("?")[0];
      }

      dispatch(
        fetchHandleUpdateOrganisation(
          accessToken,
          orgId!,
          organisation,
          (data) => onUpdateOrganisationSuccess(data),
          (message) => onUpdateOrganisationError(message)
        )
      );
    } catch (e) {
      console.log(e);
      onUpdateOrganisationError(
        "Failed to send the organisation update request."
      );
    }
  };

  const deleteOrganisation = async () => {
    setValidated(false);
    setDisabled(true);
    try {
      dispatch(
        fetchHandleDeleteOrganisation(
          accessToken,
          orgId!,
          (data) => onUpdateOrganisationSuccess(data),
          (message) => onUpdateOrganisationError(message)
        )
      );
    } catch {}
  };

  const onUpdateOrganisationSuccess = (org: IOrganisation) => {
    const accountsRequest: IGetAccountsRequest = {
      OrganisationId: orgId!,
      LicenceTypeId: null,
      SortBy: null,
      SearchValue: null,
    };
    dispatch(fetchHandleGetAccounts(accessToken, accountsRequest));
    setDisabled(false);
    navigate(cancelUrl);
  };

  const onUpdateOrganisationError = (message: string) => {
    setDisabled(false);
    setMessage(message);
    setFailure(true);
    setShowMessage(true);
    window.scrollTo(0, 0);
  };

  const validate = () => {
    setErrorState({
      ...errorState,
      organisationName:
        organisationChanges?.organisationName?.trim().length === 0,
      organisationEmail:
        organisationChanges?.organisationEmail !== undefined
          ? !/\S+@\S+\.\S+/.test(organisationChanges?.organisationEmail)
          : false,
    });
  };

  const onCancel = () => {
    if (changesMade) {
      setShowAlert(true);
    } else {
      navigate(cancelUrl);
      return;
    }
  };

  const handleClose = () => {
    setShowAlert(false);
  };
  //#endregion

  return (
    <>
      <div className="form-page">
        {disabled ? (
          <Loader />
        ) : (
          <div className="vstack gap-4 col-md-5 mx-auto">
            <h3 className="form_heading">Edit Organisation</h3>
            {showMessage && (
              <AlertMessage
                success={!failure}
                title={failure ? "Error" : "Success"}
                message={message}
              />
            )}
            <Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="vertical" gap={4}>
                <Stack direction="horizontal" gap={4}>
                  <Form.Label>Status:</Form.Label>
                  <Status
                    suspended={organisation.suspended}
                    archived={organisation.archived}
                  />
                </Stack>
                <Form.Group className="mb-3">
                  <Stack direction="horizontal" gap={4}>
                    <Form.Label>Suspend</Form.Label>
                    <Form.Check
                      disabled={baseOrganisation?.archived}
                      type="switch"
                      name="suspended"
                      id="suspend-switch"
                      checked={organisation?.suspended}
                      onChange={handleCheckChange}
                    />
                    <Form.Label>Archive</Form.Label>
                    <Form.Check
                      type="switch"
                      name="archived"
                      id="archive-switch"
                      checked={organisation?.archived}
                      onChange={handleCheckChange}
                    />
                  </Stack>
                </Form.Group>
                {/* CURRENTLY DISABLED UNTIL PROFILE IMAGE UPLOADING FOR ORG IS SUPPORTED */}
                {/* <Form.Group className="mb-3">
                  <Form.Label>Organisation Image</Form.Label>
                  <Form.Text className="text-muted">
                    Upload organisation image.
                  </Form.Text>
                  <ImageSelector
                    imageUrl={organisation?.organisationPictureUrl}
                    onChange={handleImageChange}
                    onDeleted={handleImageDelete}
                    altStr={initials}
                    disabled={disabled}
                  />
                </Form.Group> */}
                <Form.Group className="mb-3">
                  <Form.Label>Organisation Name</Form.Label>
                  <Form.Text className="text-muted">
                    Enter the name of the organisation
                  </Form.Text>
                  <Form.Control
                    size="lg"
                    name="organisationName"
                    type="text"
                    placeholder="My Organisation Ltd"
                    value={organisation.organisationName}
                    onChange={handleChange}
                    disabled={disabled || baseOrganisation?.archived}
                    isInvalid={errorState.organisationName && validated}
                    isValid={!errorState.organisationName && validated}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Organisation Email</Form.Label>
                  <Form.Text className="text-muted">
                    Enter the primary email address for this organisation
                  </Form.Text>
                  <Form.Control
                    size="lg"
                    name="organisationEmail"
                    type="email"
                    placeholder="example@organisation.com"
                    value={organisation.organisationEmail}
                    onChange={handleChange}
                    disabled={disabled || baseOrganisation?.archived}
                    isInvalid={errorState.organisationEmail && validated}
                    isValid={!errorState.organisationEmail && validated}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Organisation description</Form.Label>
                  <Form.Text className="text-muted">
                    Enter a description for the organisation
                  </Form.Text>
                  <Form.Control
                    as="textarea"
                    maxLength={1500}
                    rows={3}
                    size="lg"
                    name="organisationDescription"
                    type="text"
                    placeholder="A short description of the organisation max length 1500 characters."
                    value={organisation.organisationDescription}
                    onChange={handleChange}
                    disabled={disabled || baseOrganisation?.archived}
                  />
                </Form.Group>

                <TagInput
                  tagList={organisation.organisationTags}
                  onTagsUpdated={onTagsUpdated}
                  disabled={disabled || baseOrganisation?.archived}
                />
              </Stack>
            </Form>
          </div>
        )}
      </div>
      <FormFooter primaryBtn={primaryBtn} secondaryBtn={secondaryBtn} />
      <ModalComponent
        actionHandler={() => navigate(cancelUrl)}
        handleClose={handleClose}
        displayModal={showAlert}
        action={"Yes"}
        modalTitle={"Cancel Organisation Changes"}
        modalBody={
          "Your unsaved changes will be lost are you sure you want to cancel?"
        }
      />
    </>
  );
};

export default EditOrganisationComponent;
