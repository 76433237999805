import { capitaliseString } from "helpers/GeneralHelpers";
import CloseIcon from "../../assets/symbols/closetag.svg";

const TagComponent = ({
  Description,
  HandleTagDeletion,
  ShowCloseIcon,
  BackgroundColour,
}) => {
  return (
    <div
      className={"divOrganisationDetailsTagItem"}
      style={{
        backgroundColor: BackgroundColour,
        justifyContent: "space-between",
      }}
    >
      <div className="tagDescription">{capitaliseString(Description)}</div>
      <div>
        <a
          href="/#"
          className="js-delete-tag s-tag--dismiss"
          title="Remove tag"
          onClick={(e) => HandleTagDeletion(e, Description)}
        >
          <img src={CloseIcon} alt="Close Icon" hidden={!ShowCloseIcon} />
        </a>
      </div>
    </div>
  );
};

export default TagComponent;
