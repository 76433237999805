import { useContext, useEffect, useState } from "react";
import { Tabs, Tab, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AccountsList from "../../components/Account/AccountsList";
import ModalComponent from "../../components/Modal/ModalComponent";
import PageHeader from "../../components/Headers/PageHeader";
import OrganisationDetailComponent from "../../components/Organisation/OrganisationDetail";
import {
  fetchHandleDeleteOrganisation,
  fetchHandleSuspendLicence,
  findOrganisation,
} from "../../helpers/Organisations/OrganisationHelpers";
import BackHeader from "components/Headers/BackHeader";
import { TokenContext } from "helpers/constants/Contexts";
import { IAccount } from "interfaces/Account/IAccount";
import { IAccountsState } from "interfaces/Account/IAccountsState";
import { IButtonProps } from "interfaces/General/IButtonProps";
import { ILicenceState } from "interfaces/Licence/ILicenseState";
import { IOrganisation } from "interfaces/Organisation/IOrganisation";
import { IOrganisationsState } from "interfaces/Organisation/IOrganisationsState";
import {
  organisationsStateSelector,
  accountsStateSelector,
  licensesStateSelector,
} from "redux/states/stateSelectors";
import Status from "components/General/Status";
import Loader from "components/Loaders/Loader";
import { IGetAccountsRequest } from "interfaces/Account/IGetAccountsRequest";
import { fetchHandleGetAccounts } from "helpers/Account/AccountHelpers";
import SvgBtn from "components/General/SvgBtn";
import infoBtn from "assets/images/info_btn.svg";
import OrganisationAuditWindow from "components/Audit/OrganisationAuditWindow";

const Organisation = () => {
  //#region react-redux
  const dispatch = useDispatch();
  //#endregion

  //#region react-router-dom
  const { orgId } = useParams();
  const navigate = useNavigate();
  //#endregion

  //#region states
  const organisationState: IOrganisationsState = useSelector(
    organisationsStateSelector
  );
  const accountsState: IAccountsState = useSelector(accountsStateSelector);
  const licenceState: ILicenceState = useSelector(licensesStateSelector);
  //#endregion

  //#region useStates
  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem("orgLastActiveTab")
  );
  const [displayModal, setDisplayModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [selectedLicenceTypeId, setSelectedLicenceTypeId] = useState(null);
  const [organisation, setOrganisation] = useState<IOrganisation>(
    findOrganisation(orgId!, organisationState.organisations)!
  );
  const [organisationAccountsState, setOrganisationAccountsState] =
    useState<IAccountsState>({ accounts: [], loading: true });
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [showAudit, setShowAudit] = useState(false);
  //#endregion

  //#region useEffects
  useEffect(() => {
    if (activeTab !== null || undefined) {
      sessionStorage.setItem("orgLastActiveTab", activeTab!);
    } else {
      setActiveTab("organisationdetails");
    }
  }, [activeTab]);

  useEffect(() => {
    if (!organisation) {
      navigate("/organisations");
    }
    let filteredOrgAccounts: IAccount[] = [];
    if (accountsState.accounts != null) {
      accountsState.accounts.forEach((account) => {
        if (
          account.organisation != null &&
          account.organisation.organisationId === orgId
        ) {
          filteredOrgAccounts.push(account);
        }
      });
    }
    let state: IAccountsState = {
      accounts: filteredOrgAccounts,
      loading: false,
    };
    setOrganisationAccountsState(state);
    setAccountsLoading(accountsState.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, accountsState]);
  //#endregion

  //#region constants
  const accessToken = useContext(TokenContext);
  const linkBtns: IButtonProps[] = [
    {
      text: organisation?.suspended ? "Unsuspend licence" : "Suspend licence",
      disabled: organisation?.archived,
      onClick: () => {
        const toSuspend = organisation?.suspended ? "Unsuspend" : "Suspend";
        setModalAction(toSuspend);
        setModalTitle(`${toSuspend} license`);
        setModalBody(
          `Are you sure you want to ${toSuspend.toLowerCase()} license?`
        );
        setDisplayModal(true);
      },
    },
    {
      text: "Delete organisation",
      disabled: organisation?.archived,
      onClick: () => {
        setModalAction("Delete");
        setModalTitle("Delete organisation");
        setModalBody(
          `Are you sure you want to delete ${organisation?.organisationName} and all associated accounts?`
        );
        setDisplayModal(true);
      },
    },
  ];
  //#endregion

  //#region functions
  const handleCloseModal = () => {
    setDisplayModal(false);
  };

  const handleSelectedLicenceType = (selectedLicenceTypeId) => {
    setSelectedLicenceTypeId(selectedLicenceTypeId);
    setActiveTab("useraccounts");
  };

  const modalClicked = (action) => {
    if (action === "Suspend" || modalAction === "Unsuspend") {
      dispatch(
        fetchHandleSuspendLicence(
          accessToken,
          organisation,
          (org) => {
            setOrganisation(org);
            setDisplayModal(false);
            const accountsRequest: IGetAccountsRequest = {
              OrganisationId: orgId!,
              LicenceTypeId: null,
              SortBy: null,
              SearchValue: null,
            };
            dispatch(fetchHandleGetAccounts(accessToken, accountsRequest));
          },
          (message) => {
            //need to do some actual error handling
            console.log(message);
          }
        )
      );
    } else if (modalAction === "Delete") {
      dispatch(
        fetchHandleDeleteOrganisation(
          accessToken,
          organisation?.organisationId,
          (org) => {
            setDisplayModal(false);
            navigate("/");
            const accountsRequest: IGetAccountsRequest = {
              OrganisationId: orgId!,
              LicenceTypeId: null,
              SortBy: null,
              SearchValue: null,
            };
            dispatch(fetchHandleGetAccounts(accessToken, accountsRequest));
          },
          (message) => {
            //need to do some actual error handling
            console.log(message);
          }
        )
      );
    }
  };
  //#endregion

  return (
    <>
      <BackHeader navigateUrl={"/organisations"} />
      <PageHeader
        title={organisation?.organisationName}
        spanBtn={{
          text: "Edit Organisation",
          onClick: () =>
            navigate(`/organisations/edit/${organisation?.organisationId}`),
        }}
        linkBtns={linkBtns}
      />
      <Stack className="divOrganisationHeader" direction="horizontal" gap={4}>
        <label className="form-label font-weight-bold">Status:</label>
        <Status
          suspended={organisation?.suspended}
          archived={organisation?.archived}
        />
        <SvgBtn icon={infoBtn} onClick={() => setShowAudit(true)} />
      </Stack>
      <Tabs
        defaultActiveKey="organisations"
        activeKey={activeTab!}
        // @ts-ignore
        onSelect={(tabKey) => setActiveTab(tabKey)}
        id="mainTabs"
        className="mb-1"
      >
        <Tab eventKey="organisationdetails" title="Organisation detail">
          <OrganisationDetailComponent
            organisation={organisation}
            handleDisplayAccounts={handleSelectedLicenceType}
          />
        </Tab>
        <Tab eventKey="useraccounts" title={"User accounts"}>
          {accountsLoading ? (
            <Loader />
          ) : (
            <AccountsList
              activeTab={activeTab}
              filterPrefix={organisation?.organisationId}
              accountsState={organisationAccountsState}
              selectedLicenceTypeId={selectedLicenceTypeId}
              licenceTypes={licenceState.licences}
              addAccountUrl={`/organisations/${orgId}/accounts/add`}
              editAccountUrl={`/organisations/${orgId}/accounts/edit/`}
              disabled={organisation?.archived}
            />
          )}
        </Tab>
      </Tabs>
      <ModalComponent
        actionHandler={modalClicked}
        handleClose={handleCloseModal}
        displayModal={displayModal}
        action={modalAction}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
      <OrganisationAuditWindow
        organisation={organisation}
        show={showAudit}
        handleClose={() => setShowAudit(false)}
      />
    </>
  );
};

export default Organisation;
