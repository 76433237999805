import { AxiosResponse } from "axios";
import EdifyPrivateService from "./EdifyPrivateService";
import { IServiceResponse } from "../interfaces/General/IServiceResponse";
import { IOrganisation } from "../interfaces/Organisation/IOrganisation";
import { IUpdateOrganisationRequestObject } from "interfaces/Organisation/IUpdateOrganisationRequest";
import { ICreateOrganisationRequestObject } from "interfaces/Organisation/ICreateOrganisationRequest";

class OrganisationService extends EdifyPrivateService {
  getOrganisations(): Promise<
    AxiosResponse<IServiceResponse<IOrganisation[]>>
  > {
    return this.http.get("v1/organisations", {
      params: {
        sortBy: "Ascending",
      },
    });
  }

  getOrganisation(
    organisationId: string
  ): Promise<AxiosResponse<IServiceResponse<IOrganisation[]>>> {
    return this.http.get("v1/organisations", {
      params: {
        organisationId: organisationId,
        sortBy: "Ascending",
      },
    });
  }

  createOrganisation(
    organisationParams: ICreateOrganisationRequestObject
  ): Promise<AxiosResponse<IServiceResponse<IOrganisation>>> {
    return this.http.post("v1/organisation", {
      organisationName: organisationParams.organisationName,
      email: organisationParams.organisationEmail,
      description: organisationParams.organisationDescription,
      image: organisationParams.organisationPictureUrl,
      tags: organisationParams.organisationTags,
    });
  }

  suspendOrgnanisation(
    organisationId,
    suspended
  ): Promise<AxiosResponse<IServiceResponse<IOrganisation>>> {
    return this.http.patch(`v1/organisation/${organisationId}`, {
      suspended: suspended,
    });
  }

  updateOrganisation(
    organisationParams: IUpdateOrganisationRequestObject,
    organisationId
  ): Promise<AxiosResponse<IServiceResponse<IOrganisation>>> {
    return this.http.patch(`v1/organisation/${organisationId}`, {
      organisationName: organisationParams.organisationName,
      email: organisationParams.organisationEmail,
      description: organisationParams.organisationDescription,
      image: organisationParams.organisationPictureUrl,
      tags: organisationParams.organisationTags,
      suspended: organisationParams.suspended,
      archived: organisationParams.archived,
    });
  }
}

export default OrganisationService;
