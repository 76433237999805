import TagComponentMapper from "components/Mappers/TagComponentMapper";
import { maxTags } from "helpers/constants/OrganisationConstants";
import { concatStringTagsToArray } from "helpers/GeneralHelpers";
import { useEffect, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";

const TagInput = ({ tagList, onTagsUpdated, disabled = false }) => {
  const [tags, setTags] = useState<string>("");
  const [tagArray, setTagArray] = useState<string[]>(tagList);

  useEffect(() => {
    setTagArray(tagList);
  }, [tagList]);

  const handleChangeTags = (event) => {
    setTags(event.target.value);
  };

  const handleAddTags = (event) => {
    event.preventDefault();
    if (tags?.length !== 0 && tagArray.length < maxTags) {
      let updatedTags = concatStringTagsToArray(tags, tagArray);
      onTagsUpdated(updatedTags);
      setTags("");
    }
  };

  const handleTagDeletion = (event, tag) => {
    event.preventDefault();
    const index = tagArray.indexOf(tag);

    if (index !== undefined && index > -1) {
      let temp = tagArray?.filter((t) => t !== tag);
      onTagsUpdated(temp);
    }
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Tags</Form.Label>
        <Form.Text className="text-muted">
          Enter the tags associated with this organisation
        </Form.Text>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Comma, seperated, tags"
            size="lg"
            name="organisationTags"
            type="text"
            value={tags}
            onChange={handleChangeTags}
            disabled={disabled}
          />
          <Button
            onClick={handleAddTags}
            variant="input"
            id="button-addon2"
            disabled={disabled}
          >
            Add
          </Button>
        </InputGroup>
        <div>
          <span className="my-1 spanTagItem">
            <TagComponentMapper
              disabled={disabled}
              tags={tagArray}
              onDelete={handleTagDeletion}
              showCloseIcon={true}
            />
          </span>
        </div>
      </Form.Group>
    </>
  );
};

export default TagInput;
