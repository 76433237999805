import Loader from "components/Loaders/Loader";
import AuditLogEntryMapper from "components/Mappers/AuditLogEntryMapper";
import AlertMessage from "components/Message/AlertMessage";
import { fetchHandleGetAuditLogs } from "helpers/Audit/AuditHelpers";
import { TokenContext } from "helpers/constants/Contexts";
import { AuditOperationCategories } from "helpers/enums/AuditOperationCategories";
import { IAuditLog } from "interfaces/Audit/IAuditLog";
import { useContext, useState, useEffect } from "react";
import { Col, Container, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";

const OrganisationAuditWindow = ({
  show = false,
  handleClose,
  organisation,
}) => {
  const dispatch = useDispatch();
  const accessToken = useContext(TokenContext);
  const [auditLogs, setAuditLogs] = useState<IAuditLog[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(true);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (show) {
      getAuditLogs(organisation.organisationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const getAuditLogs = (entityId: string) => {
    dispatch(
      fetchHandleGetAuditLogs(
        accessToken,
        entityId,
        (logs) => onGetAuditLogsSuccess(logs),
        (message) => onGetAuditLogsFailure(message)
      )
    );
  };

  const onGetAuditLogsSuccess = (logs: IAuditLog[]) => {
    setShowError(false);
    setErrorMessage("");
    setAuditLogs(logs);
    setLoading(false);
  };

  const onGetAuditLogsFailure = (message: string) => {
    setErrorMessage(message);
    setShowError(true);
    setLoading(false);
  };
  return (
    <>
      <Modal
        contentClassName="audit-content"
        dialogClassName="audit-modal-body"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="bg-primary" closeButton closeVariant="white">
          <Modal.Title className="w-100 text-center modal-label white font-weight-bold">
            Organisation History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="audit-body p-5">
          {loading ? (
            <Loader />
          ) : showError ? (
            <AlertMessage
              success={false}
              title={"Error"}
              message={errorMessage}
            />
          ) : (
            <>
              <Container className="p-3">
                <Row>
                  <Col>
                    <label className="font-weight-bold modal-label">
                      {organisation.organisationName}
                    </label>
                  </Col>
                </Row>
              </Container>
              <Tabs defaultActiveKey="details" className="mb-1 px-0">
                <Tab eventKey="details" title="Details">
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Description</th>
                        <th>Changed by</th>
                        <th>Date of change</th>
                        <th>
                          {"Time "}
                          <span className="font-weight-light font-sm">{`(${timezone})`}</span>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Organisation Details Audit Entry Mapper goes here */}
                      <AuditLogEntryMapper
                        auditLogs={auditLogs}
                        operationCategories={[
                          AuditOperationCategories.DetailsChange,
                        ]}
                      />
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="status" title="Status">
                  <Table>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Changed by</th>
                        <th>Date of change</th>
                        <th>
                          {"Time "}
                          <span className="font-weight-light font-sm">{`(${timezone})`}</span>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Organisation Status Audit Entry Mapper goes here */}
                      <AuditLogEntryMapper
                        auditLogs={auditLogs}
                        operationCategories={[
                          AuditOperationCategories.StatusChange,
                        ]}
                      />
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrganisationAuditWindow;
