import {
  tokenValid,
  fetchHandleGetAccessToken,
} from "helpers/Auth/AuthHelpers";
import { tagsApiBaseUrl } from "helpers/constants/TagConstants";
import { IServiceResponse } from "interfaces/General/IServiceResponse";
import { ITag } from "interfaces/Tag/ITag";
import {
  getTagsError,
  getTagsRequest,
  getTagsSuccess,
} from "redux/actions/tag/tagAction";
import TagService from "services/TagServices";

export function fetchHandleGetTags(accessToken: string) {
  return async function fetchHandleGetTagsThunk(dispatch) {
    dispatch(getTagsRequest());

    try {
      //validate token refresh if needed
      if (!tokenValid(accessToken)) {
        await dispatch(fetchHandleGetAccessToken()).then(
          (token) => (accessToken = token)
        );
      }
      const service = new TagService(tagsApiBaseUrl, accessToken!);

      const response = await service.getTag();

      const serviceResponse = response.data as IServiceResponse<ITag[]>;

      dispatch(getTagsSuccess(serviceResponse.data));
    } catch (err) {
      const errMessage = `Failed to get tags list. Error: ${err}`;
      dispatch(getTagsError(errMessage));
    }
  };
}
