import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LogoutOptions, useAuth0 } from "@auth0/auth0-react";

const NavbarHeader = () => {
  const { isAuthenticated, logout } = useAuth0();

  const handleLogout = (evt) => {
    console.log("logout request initiated...");

    const auth0LogoutOptions: LogoutOptions = {
      returnTo: window.location.origin,
      federated: false,
      localOnly: false,
    };
    logout(auth0LogoutOptions);
  };

  return (
    <Navbar expand="lg" data-testid="header">
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto"></Nav>
        <Nav>
          {isAuthenticated && (
            <Nav.Link as={Link} to="/" onClick={handleLogout}>
              Logout
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarHeader;
