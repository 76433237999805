import AccountService from "services/AccountService";
import { ICreateAccountRequest } from "interfaces/Account/ICreateAccountRequest";
import { IServiceResponse } from "interfaces/General/IServiceResponse";
import {
  createAccountError,
  createAccountRequest,
  createAccountSuccess,
  deleteAccountError,
  deleteAccountRequest,
  deleteAccountSuccess,
  getAccountsError,
  getAccountsRequest,
  getAccountsSuccess,
  getOrganisationAccountsSuccess,
  updateAccountError,
  updateAccountRequest,
  updateAccountSuccess,
} from "interfaces/Account/IAccountActions";
import { IAccount } from "interfaces/Account/IAccount";
import { IUpdateAccountRequest } from "interfaces/Account/IUpdateAccountRequest";
import { IGetAccountsRequest } from "interfaces/Account/IGetAccountsRequest";
import { accountApiBaseUrl } from "helpers/constants/AccountConstants";
import { SortOptions } from "helpers/enums/SortOptions";
import { fetchHandleGetOrganisation } from "helpers/Organisations/OrganisationHelpers";
import {
  fetchHandleGetAccessToken,
  tokenValid,
} from "helpers/Auth/AuthHelpers";

export function filterAccountsByString(param: string, accounts: IAccount[]) {
  param = param.toLowerCase().trim();
  let filteredAccounts = accounts?.filter(
    (a) =>
      `${a.firstName} ${a.lastName}`.toLowerCase().includes(param) ||
      a.email.toLowerCase().includes(param)
  );
  return filteredAccounts;
}

export function filterAccountsByLicenceType(
  param: string,
  accounts: IAccount[]
) {
  let filteredAccounts = accounts;
  if (param.toLowerCase() !== "all") {
    filteredAccounts = filteredAccounts?.filter(
      (a) => a["licence"]["licenceTypeId"].toLowerCase() === param.toLowerCase()
    );
  }
  return filteredAccounts;
}

export function sortAccounts(accounts: IAccount[], sortOption: string) {
  let sorted = accounts.slice().sort(compareAccountByFirstName);
  if (sortOption === SortOptions.Descending) {
    return sorted.reverse();
  }
  return sorted;
}

function compareAccountByFirstName(a, b) {
  if (`${a.firstName}${a.lastName}` < `${b.firstName}${b.lastName}`) {
    return -1;
  }
  if (`${a.firstName}${a.lastName}` > `${b.firstName}${b.lastName}`) {
    return 1;
  }
  return 0;
}

export function fetchHandleGetAccounts(
  accessToken: string,
  query: IGetAccountsRequest
) {
  return async function fetchHandleGetAccountsThunk(dispatch) {
    dispatch(getAccountsRequest(query));
    try {
      //validate token refresh if needed
      if (!tokenValid(accessToken)) {
        await dispatch(fetchHandleGetAccessToken()).then(
          (token) => (accessToken = token)
        );
      }
      const accountService = new AccountService(accountApiBaseUrl, accessToken);
      const response = await accountService.getAccounts(query);
      const serviceResponse = response.data as IServiceResponse<IAccount[]>;
      if (query.OrganisationId) {
        dispatch(getOrganisationAccountsSuccess(serviceResponse.data));
      } else {
        dispatch(getAccountsSuccess(serviceResponse.data));
      }
    } catch (error) {
      const errorMessage = `Failed to get accounts list. Error: ${error}`;
      dispatch(getAccountsError(errorMessage));
    }
  };
}

export function fetchHandleCreateAccount(
  accessToken: string,
  account: ICreateAccountRequest,
  onSuccess: (data: IAccount) => void,
  onFailure: (message: string) => void
) {
  return async function fetchHandleCreateAccountThunk(dispatch) {
    dispatch(createAccountRequest(account));
    try {
      //validate token refresh if needed
      if (!tokenValid(accessToken)) {
        await dispatch(fetchHandleGetAccessToken()).then(
          (token) => (accessToken = token)
        );
      }
      const accountService = new AccountService(accountApiBaseUrl, accessToken);
      const response = await accountService.createAccount(account);
      const serviceResponse: IServiceResponse<IAccount> = response.data;
      onSuccess(serviceResponse.data);
      dispatch(createAccountSuccess(serviceResponse.data));
      if (account.organisationId?.length !== 0) {
        dispatch(
          fetchHandleGetOrganisation(accessToken, account.organisationId!)
        );
      }
    } catch (error) {
      onFailure(`Error creating account: ${error}`);
      console.log(error);
      dispatch(createAccountError());
      //some actual error handling needs to happen here (pass to another helper?)
    }
  };
}

export function fetchHandleDeleteAccount(
  accessToken: string,
  accountId: string,
  onSuccess: (data: any) => void = (data) => data,
  onFailure: (message: string) => void = (data) => data
) {
  return async function fetchHandleDeleteAccountThunk(dispatch, getState) {
    dispatch(deleteAccountRequest(accountId));
    try {
      //validate token refresh if needed
      if (!tokenValid(accessToken)) {
        await dispatch(fetchHandleGetAccessToken()).then(
          (token) => (accessToken = token)
        );
      }
      let updateAccount: IUpdateAccountRequest = {
        archived: true,
      };
      const service = new AccountService(accountApiBaseUrl, accessToken);
      const response = await service.updateAccount(accountId, updateAccount);
      const serviceResponse = response.data as IServiceResponse<IAccount>;
      dispatch(deleteAccountSuccess(serviceResponse.data));
      onSuccess(serviceResponse.data);
    } catch (err) {
      const errorMessage = `Failed to delete account. Error: ${err}`;
      dispatch(deleteAccountError(errorMessage));
      onFailure(errorMessage);
    }
  };
}

export function fetchHandleUpdateAccount(
  accessToken: string,
  account: IUpdateAccountRequest,
  accountId: string,
  baseAccount: IUpdateAccountRequest,
  onSuccess: (data: IAccount) => void,
  onFailure: (message: string) => void
) {
  return async function fetchHandleUpdateAccountThunk(dispatch) {
    dispatch(updateAccountRequest(account));
    try {
      //validate token refresh if needed
      if (!tokenValid(accessToken)) {
        await dispatch(fetchHandleGetAccessToken()).then(
          (token) => (accessToken = token)
        );
      }
      const accountService = new AccountService(accountApiBaseUrl, accessToken);
      const response = await accountService.updateAccount(accountId, account);
      const serviceResponse: IServiceResponse<IAccount> = response.data;
      dispatch(updateAccountSuccess(serviceResponse.data));
      onSuccess(serviceResponse.data);
      //Refresh org state to reflect org change
      if (
        baseAccount.organisationId?.length !== 0 &&
        baseAccount.organisationId !== account.organisationId
      ) {
        dispatch(
          fetchHandleGetOrganisation(accessToken, baseAccount.organisationId!)
        );
      }
      if (account.organisationId?.length !== 0) {
        dispatch(
          fetchHandleGetOrganisation(accessToken, account.organisationId!)
        );
      }
    } catch (error) {
      dispatch(updateAccountError());
      //return error todo handle actual error here
      onFailure("Failed to update the specified account.");
    }
  };
}
