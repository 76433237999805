import IRootState from "interfaces/IRootState";
import { combineReducers } from "redux";
import { accountReducer } from "redux/reducers/account/AccountReducers";
import { authReducer } from "redux/reducers/auth/authReducers";
import { licenceReducer } from "redux/reducers/licence/licenceReducers";
import { organisationReducer } from "redux/reducers/organisation/organisationReducers";
import { tagReducers } from "redux/reducers/tag/tagReducers";

const rootReducer = combineReducers<IRootState>({
  auth: authReducer,
  organisationState: organisationReducer,
  licenseState: licenceReducer,
  accountsState: accountReducer,
  tagState: tagReducers,
});

export default rootReducer;
