const SidePanelMenuItem = (props) => {
  return (
    <>
      <a
        data-rb-event-key="overview"
        className="sidebar-menu-nav-link"
        role="button"
        tabIndex={0}
        href="/organisations"
      >
        <span className={props.iconName}> </span>
        <span className="sidebar-menu-nav-title">{props.description}</span>
      </a>
    </>
  );
};

export default SidePanelMenuItem;
