import { AxiosResponse } from "axios";
import EdifyPrivateService from "./EdifyPrivateService";
import { IServiceResponse } from "../interfaces/General/IServiceResponse";
import { ILicenceType } from "interfaces/Licence/ILicenceType";

class LicenceService extends EdifyPrivateService {
  getLicenceTypes(): Promise<AxiosResponse<IServiceResponse<ILicenceType[]>>> {
    return this.http.get("v1/licences");
  }
}

export default LicenceService;
