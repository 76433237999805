import { ITag } from "interfaces/Tag/ITag";
import { ITagAction } from "interfaces/Tag/ITagAction";
import { TagActionTypes } from "./tagActionTypes";

export function getTagsRequest(): ITagAction {
  return {
    type: TagActionTypes.GET_TAGS_REQUEST,
    data: null,
  };
}

export function getTagsSuccess(tags: ITag[]): ITagAction {
  return {
    type: TagActionTypes.GET_TAGS_SUCCESS,
    data: tags,
  };
}

export function getTagsError(errorMessage: string): ITagAction {
  return {
    type: TagActionTypes.GET_TAGS_ERROR,
    data: null,
    error: errorMessage,
  };
}
