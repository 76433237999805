import { useNavigate } from 'react-router-dom';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        const redirectUrl = appState?.returnTo ?? window.location.pathname;
        navigate(redirectUrl);
    };

    const auth0ProviderOptions: Auth0ProviderOptions = {
        cacheLocation: process.env.REACT_APP_AUTH0_CACHE_LOCATION! === "localstorage" ? "localstorage" : "memory",
        useRefreshTokens: process.env.REACT_APP_AUTH0_USE_REFRESHTOKEN! === "true" ? true : false,
        domain: process.env.REACT_APP_AUTH0_DOMAIN!,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
        redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI!,
        onRedirectCallback: onRedirectCallback
    };

    return (
        <Auth0Provider {...auth0ProviderOptions}>
            {children}
        </Auth0Provider>
    );
};

export default AuthProvider;