import { generateMappingKey, capitaliseString } from "helpers/GeneralHelpers";
import { Stack, Button } from "react-bootstrap";

const OrganisationTagsComponent = ({ title, tags, onSelected }) => {
  return (
    <Stack direction="horizontal" gap={3}>
      <label className="label_bold">{title}</label>
      {tags?.map((tag) => {
        return (
          <Button
            key={generateMappingKey(tag)}
            variant="tag"
            onClick={(e) => {
              e.preventDefault();
              onSelected(tag);
            }}
          >
            {capitaliseString(tag)}
          </Button>
        );
      })}
    </Stack>
  );
};

export default OrganisationTagsComponent;
