import { fetchHandleDeleteAccount } from "helpers/Account/AccountHelpers";
import { highlightMatchInString } from "helpers/GeneralHelpers";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Status from "components/General/Status";
import infoBtn from "assets/images/info_btn.svg";
import SvgBtn from "components/General/SvgBtn";
import AccountAuditWindow from "components/Audit/AccountAuditWindow";

const AccountEntryComponent = ({
  account,
  accessToken,
  editAccountUrl,
  searchValue,
}) => {
  //#region constants
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [showAudit, setShowAudit] = useState(false);
  //#endregion

  //#region functions
  const onRemoveClicked = () => {
    setShowAlert(true);
  };

  const handleDeleteConfirmed = () => {
    dispatch(fetchHandleDeleteAccount(accessToken, account.accountId));
    setShowAlert(false);
  };

  const handleClose = () => {
    setShowAlert(false);
  };
  //#endregion
  return (
    <>
      <tr>
        <td>
          <div className="column_flex">
            <span className="label_bold">
              {highlightMatchInString(
                searchValue,
                `${account.firstName} ${account.lastName}`
              )}
            </span>

            <label>{highlightMatchInString(searchValue, account.email)}</label>
          </div>
        </td>
        <td>{account.organisation?.organisationName}</td>
        <td>
          <Status archived={account.archived} suspended={account.suspended} />
        </td>
        <td>{account.licence.licenceName}</td>
        <td>
          <Moment format="DD/MM/YYYY">{account.licence.nextInvoiceDate}</Moment>
        </td>
        <td>
          <div className="row_flex">
            <SvgBtn icon={infoBtn} onClick={() => setShowAudit(true)} />
            <Button variant="link" onClick={() => navigate(editAccountUrl)}>
              Edit
            </Button>
            <Button
              variant="link"
              disabled={account.archived}
              onClick={onRemoveClicked}
            >
              Remove
            </Button>
          </div>
        </td>
      </tr>
      <Modal show={showAlert} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the selected account? This action
          cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={account.archived}
            onClick={handleDeleteConfirmed}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <AccountAuditWindow
        account={account}
        show={showAudit}
        handleClose={() => setShowAudit(false)}
      ></AccountAuditWindow>
    </>
  );
};

export default AccountEntryComponent;
