const SvgBtn = ({ icon, onClick }) => {
  return (
    <>
      <button type="button" className="btn-icon" onClick={onClick}>
        <img src={icon} alt="Info Icon" />
      </button>
    </>
  );
};

export default SvgBtn;
