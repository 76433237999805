import { toCamelCase } from "helpers/Audit/AuditHelpers";
import { IAccount } from "interfaces/Account/IAccount";
import { useEffect, useState } from "react";
import Moment from "react-moment";

const AccountDetailsAuditEntry = ({ responseBody, requestor, timestamp }) => {
  const [account, setAccount] = useState<IAccount>();

  useEffect(() => {
    try {
      const account = JSON.parse(responseBody, toCamelCase);
      if (account) {
        setAccount(account);
      }
    } catch (e) {
      console.log("Invalid json in audit response body");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {account && (
        <tr>
          <td>{account?.firstName}</td>
          <td>{account?.lastName}</td>
          <td>{account?.organisation?.organisationName}</td>
          <td>{requestor}</td>
          <td>
            <Moment format="DD/MM/YYYY">{timestamp}</Moment>
          </td>
          <td>
            <Moment format="HH:mm:ss">{timestamp}</Moment>
          </td>
        </tr>
      )}
    </>
  );
};

export default AccountDetailsAuditEntry;
