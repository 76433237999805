import { Button, Stack } from "react-bootstrap";
import { IHeaderProps } from "../../interfaces/General/IHeaderProps";

const PageHeader = ({ title, spanBtn, linkBtns }: IHeaderProps) => {
  return (
    <>
      <div className="divOrganisationHeader">
        <Stack direction="vertical" gap={4}>
          <Stack direction="horizontal">
            <h1 className="main_heading">{title}</h1>
            <Button
              className="ms-auto"
              onClick={spanBtn.onClick}
              variant="primary"
              disabled={spanBtn.disabled}
            >
              {spanBtn.text}
            </Button>
          </Stack>
          {linkBtns && (
            <Stack direction="horizontal" gap={3}>
              {linkBtns.map((btn) => (
                <Button
                  key={btn.text}
                  variant="link"
                  onClick={btn.onClick}
                  disabled={btn.disabled}
                >
                  {btn.text}
                </Button>
              ))}
            </Stack>
          )}
        </Stack>
      </div>
    </>
  );
};

export default PageHeader;
