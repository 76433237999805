import { IAccount } from "interfaces/Account/IAccount";
import { IAccountAction } from "interfaces/Account/IAccountActions";
import { IAccountsState } from "interfaces/Account/IAccountsState";
import { AccountActionTypes } from "redux/actions/account/accountActionTypes";

const initialState: IAccountsState = {
  accounts: [],
  loading: false,
};

export function accountReducer(
  state: IAccountsState = initialState,
  action: IAccountAction
): IAccountsState {
  switch (action.type) {
    case AccountActionTypes.GET_ACCOUNTS_REQUEST:
      return { ...state, loading: true, error: undefined };

    case AccountActionTypes.GET_ACCOUNTS_SUCCESS: {
      const accounts: IAccount[] =
        action.data instanceof Array
          ? (action.data as IAccount[])
          : [action.data as IAccount];
      return { ...state, loading: false, accounts: accounts, error: undefined };
    }

    case AccountActionTypes.GET_ORG_ACCOUNTS_SUCCESS: {
      const accounts: IAccount[] =
        action.data instanceof Array
          ? (action.data as IAccount[])
          : [action.data as IAccount];

      const orgId = accounts[0]?.organisation!.organisationId;

      const updatedAccounts = orgId
        ? state.accounts.map((acc) => {
            return acc.organisation?.organisationId === orgId
              ? accounts.find((a) => a.accountId === acc.accountId)!
              : acc;
          })
        : state.accounts;

      return {
        ...state,
        loading: false,
        accounts: updatedAccounts,
        error: undefined,
      };
    }

    case AccountActionTypes.GET_ACCOUNTS_ERROR:
      return { ...state, loading: false, error: action.data as string };

    case AccountActionTypes.UPDATE_ACCOUNT_SUCCESS: {
      const accountToUpdate = action.data as IAccount;
      const updatedAccounts = state.accounts.map((acc) => {
        return acc.accountId === accountToUpdate.accountId
          ? accountToUpdate
          : acc;
      });

      return {
        ...state,
        loading: false,
        accounts: updatedAccounts,
        error: undefined,
      };
    }

    case AccountActionTypes.CREATE_ACCOUNT_SUCCESS: {
      const accountToAdd = action.data as IAccount;
      state.accounts.push(accountToAdd);
      state.accounts.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
      return {
        ...state,
        loading: false,
        accounts: state.accounts,
        error: undefined,
      };
    }

    case AccountActionTypes.DELETE_ACCOUNT_SUCCESS: {
      const accountToUpdate = action.data as IAccount;
      const updatedAccounts = state.accounts.map((acc) => {
        return acc.accountId === accountToUpdate.accountId
          ? accountToUpdate
          : acc;
      });

      return {
        ...state,
        loading: false,
        accounts: updatedAccounts,
        error: undefined,
      };
    }

    default:
      return state;
  }
}
