import logo from "../../assets/images/Edify_Logo_White.svg";
import "./errorComponent.scss";

import { LogoutOptions, useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState, MouseEvent } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

function ErrorComponent() {
  const { error, isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState<number>(15);

  const landingPageUri = "/";
  const organisationsPageUri = "/organisations";

  const btnLoginUserHandler = async (event: MouseEvent) => {
    event.preventDefault();
    logoutUserAndRedirectToLandingPage();
  };

  const logoutUserAndRedirectToLandingPage = () => {
    // Clear Auth0 session cookies which seem to get set by Auth0 before Error
    const auth0LogoutOptions: LogoutOptions = {
      returnTo: window.location.origin,
      federated: false,
      localOnly: false,
    };
    logout(auth0LogoutOptions);
  };

  useEffect(() => {
    if (error) {
      return;
    }

    if (!isAuthenticated) {
      console.log("No error and not authenticated, navigating to login page");
      navigate(landingPageUri);
    } else {
      console.log(
        "No error and authenticated, navigating to organisations page"
      );
      navigate(organisationsPageUri);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isAuthenticated]);

  useEffect(() => {
    if (timeLeft === 0) {
      logoutUserAndRedirectToLandingPage();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <>
      {error && (
        <div className="error-container">
          <img alt="Edify" src={logo} height="68"></img>
          <p className="title">{error?.message}</p>
          <div>
            <span>
              You will automatically be redirected to a login page in{" "}
            </span>
            <span className="countdown">
              {String(timeLeft).padStart(2, "0")}
            </span>
            <span> seconds, or you can click the login button below</span>
          </div>
          <br />
          <Button variant="secondary" onClick={btnLoginUserHandler}>
            Login
          </Button>
        </div>
      )}
    </>
  );
}

export default ErrorComponent;
