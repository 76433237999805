import AlertMessage from "../Message/AlertMessage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Stack } from "react-bootstrap";

const SuccessPageComponent = () => {
  const [searchParams] = useSearchParams();

  const organisationId = searchParams.has("orgId")
    ? searchParams.get("orgId")
    : "";

  const organisationName = searchParams.has("orgName")
    ? searchParams.get("orgName")
    : "";

  const successMessage =
    organisationName === ""
      ? "Organisation was created successfully."
      : `Organisation ${organisationName} was created successfully.`;

  console.log("successfully created org with id -> ", organisationId);

  const navigate = useNavigate();

  const handleAddAccountToOrganisation = () => {
    // @ts-ignore
    navigate(`/organisations/${organisationId}/accounts/add`);
  };

  const handleAddOrganisation = () => {
    navigate("/organisations/add");
  };

  return (
    <>
      <Stack className="successPageComponent" direction="vertical" gap={4}>
        <AlertMessage
          success={true}
          title={"Success"}
          message={successMessage}
        />
        <Stack direction="horizontal">
          <Button variant="secondary" onClick={handleAddAccountToOrganisation}>
            Add accounts to organisation
          </Button>
          <Button
            className="ms-auto"
            variant="primary"
            onClick={handleAddOrganisation}
          >
            Add another organisation
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default SuccessPageComponent;
