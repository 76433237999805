import { IOrganisation } from "../../interfaces/Organisation/IOrganisation";
import { IServiceResponse } from "../../interfaces/General/IServiceResponse";
import OrganisationService from "../../services/OrganisationService";
import { ICreateOrganisationRequestObject } from "interfaces/Organisation/ICreateOrganisationRequest";
import { IUpdateOrganisationRequestObject } from "interfaces/Organisation/IUpdateOrganisationRequest";
import {
  organisationsUpdateFailed,
  organisationsDeleted,
  organisationsUpdated,
  organisationsAdded,
  organisationsAddFailed,
  organisationsUpdateRequest,
  getOrganisationsError,
  getOrganisationsSuccess,
  getOrganisationsRequest,
  getOrganisationSuccess,
  getOrganisationError,
} from "redux/actions/organisation/organisationActions";
import { organisationApiBaseUrl } from "helpers/constants/OrganisationConstants";
import { SortOptions } from "helpers/enums/SortOptions";
import {
  fetchHandleGetAccessToken,
  tokenValid,
} from "helpers/Auth/AuthHelpers";

export function fetchHandleGetOrganisations(accessToken: string) {
  return async function fetchHandleGetOrganisationsThunk(dispatch) {
    try {
      dispatch(getOrganisationsRequest());
      //validate token refresh if needed
      if (!tokenValid(accessToken)) {
        await dispatch(fetchHandleGetAccessToken()).then(
          (token) => (accessToken = token)
        );
      }
      const organisationService = new OrganisationService(
        organisationApiBaseUrl,
        accessToken
      );
      const response = await organisationService.getOrganisations();
      const serviceResponse = response.data as IServiceResponse<
        IOrganisation[]
      >;
      dispatch(getOrganisationsSuccess(serviceResponse.data));
    } catch (error) {
      const errMessage = `Failed to get organisations list. Error: ${error}`;
      dispatch(getOrganisationsError(errMessage));
    }
  };
}

export function fetchHandleGetOrganisation(
  accessToken: string,
  organisationId: string
) {
  return async function fetchHandleGetOrganisationsThunk(dispatch) {
    try {
      //validate token refresh if needed
      if (!tokenValid(accessToken)) {
        await dispatch(fetchHandleGetAccessToken()).then(
          (token) => (accessToken = token)
        );
      }
      const organisationService = new OrganisationService(
        organisationApiBaseUrl,
        accessToken
      );
      const response = await organisationService.getOrganisation(
        organisationId
      );
      const serviceResponse = response.data as IServiceResponse<
        IOrganisation[]
      >;
      dispatch(getOrganisationSuccess(serviceResponse.data[0]));
    } catch (error) {
      const errMessage = `Failed to get single organisation. Error: ${error}`;
      dispatch(getOrganisationError(errMessage));
    }
  };
}

export function fetchHandleDeleteOrganisation(
  accessToken: string,
  organisationId: string,
  onSuccess: (data: any) => void,
  onFailure: (message: string) => void
) {
  return async function fetchHandleDeleteOrganisationThunk(dispatch, getState) {
    //validate token refresh if needed
    if (!tokenValid(accessToken)) {
      await dispatch(fetchHandleGetAccessToken()).then(
        (token) => (accessToken = token)
      );
    }
    const organisationApiBaseUrl = process.env.REACT_APP_API_BASE_URL!;
    const organisationService = new OrganisationService(
      organisationApiBaseUrl,
      accessToken
    );

    const updateOrganisation: IUpdateOrganisationRequestObject = {
      archived: true,
    };
    const response = await organisationService.updateOrganisation(
      updateOrganisation,
      organisationId
    );
    if (response.status !== 200) {
      dispatch(organisationsUpdateFailed("Failed to delete organisation"));
      onFailure("Failed to delete organisation");
    } else {
      const serviceResponse = response.data as IServiceResponse<IOrganisation>;
      dispatch(organisationsDeleted(serviceResponse.data));
      onSuccess(serviceResponse.data);
    }
  };
}

export function fetchHandleSuspendLicence(
  accessToken: string,
  organisation: IOrganisation,
  onSuccess: (data: any) => void,
  onFailure: (message: string) => void
) {
  return async function fetchHandleSuspendLicenceThunk(dispatch, getState) {
    //validate token refresh if needed
    if (!tokenValid(accessToken)) {
      await dispatch(fetchHandleGetAccessToken()).then(
        (token) => (accessToken = token)
      );
    }
    const organisationApiBaseUrl = process.env.REACT_APP_API_BASE_URL!;
    const organisationService = new OrganisationService(
      organisationApiBaseUrl,
      accessToken
    );

    const toSuspend = !organisation.suspended;

    const response = await organisationService.suspendOrgnanisation(
      organisation.organisationId,
      toSuspend
    );
    if (response.status !== 200) {
      dispatch(organisationsUpdateFailed("Failed suspending organisation"));
      onFailure("Failed to suspend organisation");
    } else {
      const serviceResponse = response.data as IServiceResponse<IOrganisation>;
      dispatch(organisationsUpdated(serviceResponse.data));
      onSuccess(serviceResponse.data);
    }
  };
}

export function findOrganisation(
  orgId: string,
  organisations: IOrganisation[]
) {
  if (organisations === null) return null;
  const indexOfOrganisation = organisations.findIndex((org: IOrganisation) => {
    return org.organisationId === orgId;
  });
  if (indexOfOrganisation !== -1) {
    return organisations[indexOfOrganisation];
  }
  return null;
}

export function fetchHandleCreateOrganisation(
  accessToken: string,
  organisation: ICreateOrganisationRequestObject,
  onSuccess: (data: any) => void,
  onFailure: (message: string) => void
) {
  return async function fetchHandleCreateOrganisationThunk(dispatch) {
    try {
      //validate token refresh if needed
      if (!tokenValid(accessToken)) {
        await dispatch(fetchHandleGetAccessToken()).then(
          (token) => (accessToken = token)
        );
      }
      const organisationApiBaseUrl = process.env.REACT_APP_API_BASE_URL!;
      const organisationService = new OrganisationService(
        organisationApiBaseUrl,
        accessToken!
      );
      const response = await organisationService.createOrganisation(
        organisation
      );
      const serviceResponse = response.data as IServiceResponse<IOrganisation>;
      dispatch(organisationsAdded(serviceResponse.data));
      onSuccess(serviceResponse.data);
    } catch (error) {
      let message = `Failed to create organisation: ${error}`;
      dispatch(organisationsAddFailed(message));
      onFailure(message);
    }
  };
}

export function fetchHandleUpdateOrganisation(
  accessToken: string,
  orgId: string,
  organisation: IUpdateOrganisationRequestObject,
  onSuccess: (data: any) => void,
  onFailure: (message: string) => void
) {
  return async function fetchHandleUpdateOrganisationThunk(dispatch) {
    dispatch(organisationsUpdateRequest());
    try {
      //validate token refresh if needed
      if (!tokenValid(accessToken)) {
        await dispatch(fetchHandleGetAccessToken()).then(
          (token) => (accessToken = token)
        );
      }
      const organisationService = new OrganisationService(
        process.env.REACT_APP_API_BASE_URL!,
        accessToken
      );
      const response = await organisationService.updateOrganisation(
        organisation,
        orgId
      );
      const serviceResponse: IServiceResponse<IOrganisation> = response.data;
      let org: IOrganisation = serviceResponse.data;
      dispatch(organisationsUpdated(org));
      onSuccess(org);
    } catch (error) {
      const message = `Could not update organisation: ${error}`;
      dispatch(organisationsUpdateFailed(message));
      onFailure(message);
    }
  };
}

export function filterOrganisationsByString(
  param: string,
  organisations: IOrganisation[]
) {
  param = param.toLowerCase().trim();
  let filteredOrganisations = organisations?.filter((o) =>
    o.organisationName.toLowerCase().includes(param)
  );
  return filteredOrganisations;
}

export function filterOrganisationsByTags(
  param: string,
  organisations: IOrganisation[]
) {
  let filteredOrganisations = organisations;
  if (param.toLowerCase() !== "all") {
    filteredOrganisations = filteredOrganisations?.filter((org) =>
      org.tags.some((tag) => {
        return tag.toLowerCase() === param.toLowerCase();
      })
    );
  }
  return filteredOrganisations;
}

export function sortOrganisations(
  organisations: IOrganisation[],
  sortOption: string
) {
  let sorted = organisations.slice().sort(compareOrganisationsByName);
  if (sortOption === SortOptions.Descending) {
    return sorted.reverse();
  }
  return sorted;
}

function compareOrganisationsByName(a, b) {
  if (a.organisationName < b.organisationName) {
    return -1;
  }
  if (a.organisationName > b.organisationName) {
    return 1;
  }
  return 0;
}
