export enum AccountActionTypes {
  CREATE_ACCOUNT_REQUEST = "CREATE_ACCOUNT_REQUEST",
  CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS",
  CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR",
  GET_ACCOUNTS_REQUEST = "GET_ACCOUNTS_REQUEST",
  GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS",
  GET_ORG_ACCOUNTS_SUCCESS = "GET_ORG_ACCOUNTS_SUCCESS",
  GET_ACCOUNTS_ERROR = "GET_ACCOUNTS_ERROR",
  DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST",
  DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_ERROR",
  UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST",
  UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS",
  UPDATE_ACCOUNT_ERROR = "UPDATE_ACCOUNT_ERROR",
  GET_UPLOAD_TOKEN_STARTED = "GET_UPLOAD_TOKEN_STARTED",
  GET_UPLOAD_TOKEN_SUCCESS = "GET_UPLOAD_TOKEN_SUCCESS",
  GET_UPLOAD_TOKEN_ERROR = "GET_UPLOAD_TOKEN_ERROR",
}
