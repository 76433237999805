import { AxiosResponse } from "axios";
import EdifyPrivateService from "./EdifyPrivateService";
import { IServiceResponse } from "../interfaces/General/IServiceResponse";
import { ITag } from "../interfaces/Tag/ITag";

class TagService extends EdifyPrivateService {
  getTag(): Promise<AxiosResponse<IServiceResponse<ITag[]>>> {
    return this.http.get("v1/tags");
  }
}

export default TagService;
