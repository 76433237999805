import React, { useEffect, useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";

const ModalComponent = ({
  action,
  actionHandler,
  handleClose,
  displayModal,
  modalTitle,
  modalBody,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(displayModal);
  }, [displayModal]);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Warning: {modalTitle}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="">{modalBody}</h3>
        </Modal.Body>
        <Modal.Footer>
          <Stack direction="horizontal" gap={4}>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                actionHandler(action);
              }}
            >
              {action}
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalComponent;
