import { withAuthenticationRequired } from "@auth0/auth0-react";
import FullPageLoader from "../../components/Loaders/FullPageLoader";

const ProtectedComponent = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <FullPageLoader />,
  });

  return <Component {...args} />;
};

export default ProtectedComponent;
