import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import ReportService from "services/ReportService";
import { accountApiBaseUrl } from "helpers/constants/AccountConstants";
import { TokenContext } from "helpers/constants/Contexts";
import { AxiosError } from "axios";

const Download = () => {
  //#region constants
  const token = useContext(TokenContext);
  const { reportName } = useParams();
  //#endregion

  //#region useStates
  const [errorState, setErrorState] = useState("");
  //#endregion

  //#region useEffects
  useEffect(() => {
    if (reportName && token) {
      getReport(reportName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportName, token]);
  //#endregion

  //#region functions
  const getReport = async (name: string) => {
    try {
      //send request
      const reportService = new ReportService(accountApiBaseUrl, token);
      const response = await reportService.getMigrationReport(name);

      //get report filename
      var fileName: string | null =
        response.request.getResponseHeader("X-File-Name");
      if (!fileName) {
        fileName = "migration-report.csv";
      }

      //download object
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "text/csv",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorMessage = `${error.code} ${error.message}`;
        setErrorState(errorMessage);
      } else {
        setErrorState("Download request failed.");
      }
    }
  };
  //#endregion

  return (
    <>
      <h1>{errorState}</h1>
    </>
  );
};

export default Download;
