import SuccessIcon from "../../assets/symbols/success.png";
import ErrorIcon from "../../assets/symbols/error-icon.png";

const AlertMessage = ({ success, title, message }) => {
  return (
    <>
      <div
        className={`form-message ${
          success ? `form-message-success` : `form-message-failure`
        }`}
      >
        <div>
          <img
            src={success ? SuccessIcon : ErrorIcon}
            className="message-component-icon"
            alt="message component icon"
          />
        </div>
        <div>
          <div className="message-component-title">{title}</div>
          <div className={"message-component-body"}>{message}</div>
        </div>
      </div>
    </>
  );
};

export default AlertMessage;
