import { IOrganisation } from "interfaces/Organisation/IOrganisation";
import { IOrganisationAction } from "interfaces/Organisation/IOrganisationAction";
import { OrganisationActionTypes } from "./organisationActionTypes";

export function getOrganisationsRequest(): IOrganisationAction {
  return {
    type: OrganisationActionTypes.GET_ORGANISATIONS_REQUEST,
    data: null,
    error: undefined,
  };
}

export function getOrganisationsSuccess(
  organisations: IOrganisation[]
): IOrganisationAction {
  return {
    type: OrganisationActionTypes.GET_ORGANISATIONS_SUCCESS,
    data: organisations,
    error: undefined,
  };
}

export function getOrganisationsError(
  errorMessage: string
): IOrganisationAction {
  return {
    type: OrganisationActionTypes.GET_ORGANISATIONS_ERROR,
    data: null,
    error: errorMessage,
  };
}

export function getOrganisationSuccess(
  organisation: IOrganisation
): IOrganisationAction {
  return {
    type: OrganisationActionTypes.GET_ORGANISATION_SUCCESS,
    data: organisation,
    error: undefined,
  };
}

export function getOrganisationError(
  errorMessage: string
): IOrganisationAction {
  return {
    type: OrganisationActionTypes.GET_ORGANISATION_ERROR,
    data: null,
    error: errorMessage,
  };
}

export function organisationsAdded(
  newOrganisation: IOrganisation
): IOrganisationAction {
  return {
    type: OrganisationActionTypes.CREATE_ORGANISATION_SUCCESS,
    data: newOrganisation,
    error: undefined,
  };
}

export function organisationsAddFailed(
  errorMessage: string
): IOrganisationAction {
  return {
    type: OrganisationActionTypes.CREATE_ORGANISATION_FAILED,
    data: null,
    error: errorMessage,
  };
}

export function organisationsUpdateRequest(): IOrganisationAction {
  return {
    type: OrganisationActionTypes.UPDATE_ORGANISATION_REQUEST,
    data: null,
  };
}

export function organisationsUpdateFailed(
  errorMessage: string
): IOrganisationAction {
  return {
    type: OrganisationActionTypes.UPDATE_ORGANISATION_FAILED,
    data: null,
    error: errorMessage,
  };
}

export function organisationsUpdated(
  updatedOrganisation: IOrganisation
): IOrganisationAction {
  return {
    type: OrganisationActionTypes.UPDATE_ORGANISATION_SUCCESS,
    data: updatedOrganisation,
    error: undefined,
  };
}

export function organisationsDeleted(
  deletedOrganisation: IOrganisation
): IOrganisationAction {
  return {
    type: OrganisationActionTypes.DELETE_ORGANISATION_SUCCESS,
    data: deletedOrganisation,
    error: undefined,
  };
}
