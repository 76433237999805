import { Button } from "react-bootstrap";

const FormFooter = ({ primaryBtn, secondaryBtn }) => {
  return (
    <>
      <div id="footer">
        <div className="footer-div">
          <div className="button-div-left">
            <span className="spanCreateButton">
              <Button
                variant="secondary"
                onClick={secondaryBtn.onClick}
                disabled={secondaryBtn.disabled}
              >
                {secondaryBtn.text}
              </Button>
            </span>
          </div>
          <div className="button-div-right">
            <span className="spanCreateButton">
              <Button
                variant="primary"
                onClick={primaryBtn.onClick}
                disabled={primaryBtn.disabled}
              >
                {primaryBtn.text}
              </Button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormFooter;
