import { IAuthAction } from "interfaces/Auth/IAuthAction";
import { IAuthState } from "interfaces/Auth/IAuthState";
import { AuthActionTypes } from "redux/actions/auth/authActionTypes";

const initialState: IAuthState = {
  authenticated: false,
  loading: false,
};

export function authReducer(
  state: IAuthState = initialState,
  action: IAuthAction
): IAuthState {
  switch (action.type) {
    case AuthActionTypes.AUTH_INIT:
      return {
        ...state,
        authenticated:
          action.data === null ? false : action.data.isAuthenticated,
      };
    case AuthActionTypes.AUTH_REQUEST:
      return { ...state, loading: true, error: undefined };

    case AuthActionTypes.AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.data,
        authenticated: true,
        error: undefined,
      };

    case AuthActionTypes.AUTH_ERROR:
      return {
        ...state,
        loading: false,
        authenticated: false,
        error: action.error,
      };

    default:
      return state;
  }
}
