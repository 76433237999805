import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card, Stack, Table } from "react-bootstrap";
import TagComponentMapper from "components/Mappers/TagComponentMapper";
import { IGetLicenceTypesResponseDto } from "interfaces/Licence/IGetLicenceType";
import { generateMappingKey } from "helpers/GeneralHelpers";
import Status from "components/General/Status";

const OrganisationDetailComponent = ({
  organisation,
  handleDisplayAccounts,
}) => {
  const navigate = useNavigate();
  const [licences, setLicences] = useState<IGetLicenceTypesResponseDto[]>();

  useEffect(() => {
    if (organisation) {
      const licenceTypeResponse =
        organisation.organisationLicenceTypesResponse?.licenceTypes;
      setLicences(licenceTypeResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  return (
    <>
      <div className="tab_content">
        <Stack direction="vertical" gap={4}>
          <Card>
            <Stack className="m-4" direction="vertical" gap={3}>
              <h2>General information</h2>
              <Table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td className={"tdRightAligned"}>
                      {organisation?.organisationName}
                    </td>
                  </tr>

                  <tr>
                    <td>Status</td>
                    <td className={"tdRightAligned"}>
                      <Status
                        textOnly={true}
                        suspended={organisation?.suspended}
                        archived={organisation?.archived}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Description</td>
                    <td className={"tdRightAligned"}>
                      {organisation?.description}
                    </td>
                  </tr>

                  <tr>
                    <td>User accounts</td>
                    <td className={"tdRightAligned"}>
                      {organisation?.numberOfAccounts}
                    </td>
                  </tr>
                </tbody>
                <tfoot></tfoot>
              </Table>
            </Stack>
          </Card>
          <Card>
            <Stack className="m-4" direction="vertical" gap={3}>
              <h2>Licenses</h2>
              <Stack direction="vertical" gap={4}>
                {licences?.map((licence) => {
                  return (
                    <Card key={generateMappingKey(licence.licenceTypeId)}>
                      <Stack className="m-4" direction="vertical" gap={1}>
                        <h2>{licence.licenceName}</h2>
                        <Button
                          variant="link"
                          onClick={() =>
                            handleDisplayAccounts(licence.licenceTypeId)
                          }
                        >{`${licence.numberOfLicences} accounts`}</Button>
                      </Stack>
                    </Card>
                  );
                })}
              </Stack>
            </Stack>
          </Card>
          <Card>
            <Stack className="m-4" direction="vertical" gap={3}>
              <Stack direction="horizontal">
                <h2>Tags</h2>
                <Button
                  className="ms-auto"
                  variant="link"
                  onClick={() =>
                    navigate(
                      `/organisations/edit/${organisation.organisationId}`
                    )
                  }
                >
                  Edit
                </Button>
              </Stack>
              <Stack direction="horizontal" gap={3}>
                <TagComponentMapper
                  tags={organisation?.tags}
                  onDelete={null}
                  showCloseIcon={false}
                />
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </div>
    </>
  );
};

export default OrganisationDetailComponent;
