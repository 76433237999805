import { Component } from "react";
import logo from "../../assets/images/Edify_Logo_White.svg";

class FullPageLoader extends Component {
  render() {
    return (
      <div data-testid="full-page-loader" className="fp_loading_background">
        <img alt="Edify" src={logo} height="68"></img>
        <div className="loader mt-3"></div>
      </div>
    );
  }
}

export default FullPageLoader;
