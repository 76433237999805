import { useEffect, useState } from "react";

const Status = ({ archived, suspended, textOnly = false }) => {
  //#region constants
  const [message, setMessage] = useState<string>();
  const [formatting, setFormatting] = useState<string>("success");
  //#endregion

  //#region useEffects
  useEffect(() => {
    if (archived) {
      setMessage("ARCHIVED");
      setFormatting("secondary");
    } else if (suspended) {
      setMessage("SUSPENDED");
      setFormatting("danger");
    } else {
      setMessage("ACTIVE");
      setFormatting("success");
    }
  }, [archived, suspended]);
  //#endregion
  return (
    <>
      <span
        className={
          textOnly ? `label-${formatting}` : `badge badge-${formatting}`
        }
      >
        {message}
      </span>
    </>
  );
};

export default Status;
