import AccountsList from "components/Account/AccountsList";
import PageHeader from "components/Headers/PageHeader";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import ListOrganisationComponent from "components/Organisation/OrganisationList";
import { TabOptions } from "helpers/enums/TabOptions";
import { IAccountsState } from "interfaces/Account/IAccountsState";
import { ILicenceState } from "interfaces/Licence/ILicenseState";
import { useSelector } from "react-redux";
import {
  accountsStateSelector,
  licensesStateSelector,
} from "redux/states/stateSelectors";

const OrganisationsAccounts = () => {
  const licenceState: ILicenceState = useSelector(licensesStateSelector);
  const accountsState: IAccountsState = useSelector(accountsStateSelector);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem("lastActiveTab")
  );

  useEffect(() => {
    if (activeTab !== null || undefined) {
      sessionStorage.setItem("lastActiveTab", activeTab!);
    } else {
      setActiveTab(TabOptions.Organisations);
    }
  }, [activeTab]);

  return (
    <>
      <div className="divOrganisation">
        <PageHeader
          title="Organisation & Accounts"
          spanBtn={{
            text: "Create new organisation",
            onClick: () => navigate("/organisations/add"),
          }}
        />
        <Tabs
          defaultActiveKey={TabOptions.Organisations}
          activeKey={activeTab!}
          onSelect={(tabKey) => setActiveTab(tabKey!)}
          id="mainTabs"
          className="mb-1"
        >
          <Tab eventKey={TabOptions.Organisations} title="Organisations">
            <ListOrganisationComponent activeTab={activeTab} />
          </Tab>
          <Tab eventKey={TabOptions.UserAccounts} title="User accounts">
            <AccountsList
              activeTab={activeTab}
              accountsState={accountsState}
              licenceTypes={licenceState.licences}
              addAccountUrl={`/accounts/add`}
              editAccountUrl={`/accounts/edit/`}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default OrganisationsAccounts;
