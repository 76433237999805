import { MouseEvent, useEffect } from "react";
import "./landingComponent.scss";
import logo from "../../assets/images/Edify_Logo_White.svg";
import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";

const LandingComponent = () => {
  const navigate = useNavigate();

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const btnLoginUserHandler = async (event: MouseEvent) => {
    const opts: RedirectLoginOptions = {
      redirectUri: `${window.location.origin}`,
    };

    loginWithRedirect(opts);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/organisations");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      {!isAuthenticated && (
        <main>
          <div className="main-content-container w-100">
            <div className="grid-container">
              <div className="row w-100 h-100 align-items-center">
                <div className="col-lg-3 d-none d-md-block">{/* empty */}</div>
                <div className="col-lg-9">
                  <div className="login-container">
                    <div className="logoContainer">
                      <img
                        src={logo}
                        className="logo-login mt-60"
                        alt="edify logo"
                      />{" "}
                      <span className="mx-3">Internal CMS</span>
                    </div>
                    <div className="loginButtonContainer">
                      <Button variant="primary" onClick={btnLoginUserHandler}>
                        Login
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default LandingComponent;
