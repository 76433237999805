const LicenceOptionMapper = ({ licenceTypes }) => {
  return (
    <>
      <option disabled={true} value="">
        --Select a License Type--
      </option>
      {licenceTypes?.map(({ licenceName, licenceTypeId }) => (
        <option key={licenceTypeId} value={licenceTypeId}>
          {licenceName}
        </option>
      ))}
    </>
  );
};

export default LicenceOptionMapper;
