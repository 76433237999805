import { ILicenceAction } from "interfaces/Licence/ILicenceAction";
import { LicenceActionTypes } from "./licenceActionTypes";
import { ILicenceType } from "interfaces/Licence/ILicenceType";

export function getLicencesRequest(): ILicenceAction {
  return {
    type: LicenceActionTypes.GET_LICENCES_REQUEST,
    data: null,
  };
}

export function getLicencesSuccess(licences: ILicenceType[]): ILicenceAction {
  return {
    type: LicenceActionTypes.GET_LICENCES_SUCCESS,
    data: licences,
  };
}

export function getLicencesError(errorMessage: string): ILicenceAction {
  return {
    type: LicenceActionTypes.GET_LICENCES_ERROR,
    data: null,
    error: errorMessage,
  };
}
