export function capitaliseString(str: string) {
  return str?.toLowerCase().charAt(0).toUpperCase() + str?.slice(1);
}

export function formatString(str: string, maxLength?: number) {
  if (!maxLength) {
    maxLength = str.length;
  }
  return str.trim().toLowerCase().substring(0, maxLength);
}

export function generateMappingKey(name: string) {
  return `${name}_${new Date().getTime()}`;
}

export function highlightMatchInString(searchValue: string, target: string) {
  if (searchValue.length !== 0) {
    const parts = target.split(
      new RegExp(`(${searchValue.toLowerCase().trim()})`, "gi")
    );
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            className={
              part.toLowerCase() === searchValue.toLowerCase().trim()
                ? "highlighted"
                : ""
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  } else {
    return <span>{target}</span>;
  }
}

export function concatStringTagsToArray(newTags?: string, existing?: string[]) {
  if (newTags) {
    let tagsToAdd: string[] = [];
    const tagList = newTags
      ?.split(",")
      .map((tag) => formatString(tag, 15))
      .filter((tag) => tag);

    if (existing) {
      tagList?.forEach((tag) => {
        if (
          !existing
            .map((t) => {
              return t.toLowerCase();
            })
            .includes(tag)
        ) {
          tagsToAdd.push(tag);
        }
      });

      return existing.concat(Array.from(new Set(tagsToAdd)));
    } else {
      return tagList;
    }
  }
}
