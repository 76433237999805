import { IOrganisation } from "interfaces/Organisation/IOrganisation";
import { IOrganisationAction } from "interfaces/Organisation/IOrganisationAction";
import { IOrganisationsState } from "interfaces/Organisation/IOrganisationsState";
import { OrganisationActionTypes } from "redux/actions/organisation/organisationActionTypes";

const initialState: IOrganisationsState = {
  organisations: [],
  loading: false,
};

export function organisationReducer(
  state: IOrganisationsState = initialState,
  action: IOrganisationAction
): IOrganisationsState {
  switch (action.type) {
    case OrganisationActionTypes.GET_ORGANISATIONS_REQUEST:
      return { ...state, loading: true, error: undefined };

    case OrganisationActionTypes.GET_ORGANISATIONS_SUCCESS: {
      const organisations: IOrganisation[] =
        action.data instanceof Array
          ? (action.data as IOrganisation[])
          : [action.data as IOrganisation];
      return {
        ...state,
        loading: false,
        organisations: organisations,
        error: undefined,
      };
    }

    case OrganisationActionTypes.GET_ORGANISATIONS_ERROR:
      return { ...state, loading: false, error: action.error };

    case OrganisationActionTypes.CREATE_ORGANISATION_SUCCESS: {
      const newOrganisation = action.data as IOrganisation;
      return {
        ...state,
        loading: false,
        organisations: state.organisations!.concat(newOrganisation),
        error: undefined,
      };
    }

    case OrganisationActionTypes.UPDATE_ORGANISATION_SUCCESS: {
      const organisationToUpdate = action.data as IOrganisation;
      const updatedOrganisations = state.organisations.map((org) => {
        return org.organisationId === organisationToUpdate.organisationId
          ? organisationToUpdate
          : org;
      });

      return {
        ...state,
        loading: false,
        organisations: updatedOrganisations,
        error: undefined,
      };
    }

    case OrganisationActionTypes.GET_ORGANISATION_SUCCESS: {
      const organisationToUpdate = action.data as IOrganisation;
      const updatedOrganisations = state.organisations.map((org) => {
        return org.organisationId === organisationToUpdate.organisationId
          ? organisationToUpdate
          : org;
      });

      return {
        ...state,
        loading: false,
        organisations: updatedOrganisations,
        error: undefined,
      };
    }

    case OrganisationActionTypes.GET_ORGANISATION_ERROR:
      return { ...state, loading: false, error: action.error };

    case OrganisationActionTypes.DELETE_ORGANISATION_SUCCESS: {
      const organisationToUpdate = action.data as IOrganisation;
      const updatedOrganisations = state.organisations.map((org) => {
        return org.organisationId === organisationToUpdate.organisationId
          ? organisationToUpdate
          : org;
      });

      return {
        ...state,
        loading: false,
        organisations: updatedOrganisations,
        error: undefined,
      };
    }
    default:
      return state;
  }
}
