import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const BackHeader = ({ navigateUrl }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="divOrganisation">
        <div className="divOrganisationHeader">
          <span className="spanFloatLeft">
            <Button
              variant="secondary"
              onClick={() => {
                navigate(navigateUrl);
              }}
            >
              Back
            </Button>
          </span>
        </div>
      </div>
    </>
  );
};

export default BackHeader;
