import AccountDetailsAuditEntry from "components/Audit/AccountDetailsAuditEntry";
import AccountLicenceAuditEntry from "components/Audit/AccountLicenceAuditEntry";
import AccountStatusAuditEntry from "components/Audit/AccountStatusAuditEntry";
import OrganisationDetailsAuditEntry from "components/Audit/OrganisationDetailsAuditEntry";
import OrganisationStatusAuditEntry from "components/Audit/OrganisationStatusAuditEntry";
import { sortAuditByTimestamp } from "helpers/Audit/AuditHelpers";
import { generateMappingKey } from "helpers/GeneralHelpers";
import { AuditEntityTypes } from "helpers/enums/AuditEntityTypes";
import { AuditOperationCategories } from "helpers/enums/AuditOperationCategories";
import { IAuditLog } from "interfaces/Audit/IAuditLog";
import { useEffect, useState } from "react";

const AuditLogEntryMapper = ({ auditLogs, operationCategories }) => {
  const [filteredLogs, setFilteredLogs] = useState<IAuditLog[]>([]);
  const [entityType, setEntitytype] = useState<string>("");

  useEffect(() => {
    if (auditLogs.length > 0) {
      const filteredArray = auditLogs.filter((log) =>
        operationCategories.includes(log.operationCategory)
      );
      if (filteredArray.length > 0) {
        const sortedArray = sortAuditByTimestamp(filteredArray);
        setEntitytype(sortedArray[0].entityType);
        setFilteredLogs(sortedArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditLogs]);

  const mapperSwitch = (operationCategories: AuditOperationCategories[]) => {
    switch (entityType) {
      case AuditEntityTypes.Account:
        switch (true) {
          case operationCategories.includes(
            AuditOperationCategories.LicenceChange
          ):
            return (
              <>
                {filteredLogs?.map(
                  ({ id, requestorName, timestamp, responseBody }) => (
                    <AccountLicenceAuditEntry
                      key={generateMappingKey(id)}
                      responseBody={responseBody}
                      requestor={requestorName}
                      timestamp={timestamp}
                    />
                  )
                )}
              </>
            );
          case operationCategories.includes(
            AuditOperationCategories.DetailsChange
          ) ||
            operationCategories.includes(
              AuditOperationCategories.OrganisationChange
            ):
            return (
              <>
                {filteredLogs?.map(
                  ({ id, requestorName, timestamp, responseBody }) => (
                    <AccountDetailsAuditEntry
                      key={generateMappingKey(id)}
                      responseBody={responseBody}
                      requestor={requestorName}
                      timestamp={timestamp}
                    />
                  )
                )}
              </>
            );
          case operationCategories.includes(
            AuditOperationCategories.StatusChange
          ):
            return (
              <>
                {filteredLogs?.map(
                  ({ id, requestorName, timestamp, responseBody }) => (
                    <AccountStatusAuditEntry
                      key={generateMappingKey(id)}
                      responseBody={responseBody}
                      requestor={requestorName}
                      timestamp={timestamp}
                    />
                  )
                )}
              </>
            );
          default:
            return <></>;
        }
      case AuditEntityTypes.Organisation:
        switch (true) {
          case operationCategories.includes(
            AuditOperationCategories.DetailsChange
          ):
            return (
              <>
                {filteredLogs?.map(
                  ({ id, requestorName, timestamp, responseBody }) => (
                    <OrganisationDetailsAuditEntry
                      key={generateMappingKey(id)}
                      responseBody={responseBody}
                      requestor={requestorName}
                      timestamp={timestamp}
                    />
                  )
                )}
              </>
            );
          case operationCategories.includes(
            AuditOperationCategories.StatusChange
          ):
            return (
              <>
                {filteredLogs?.map(
                  ({ id, requestorName, timestamp, responseBody }) => (
                    <OrganisationStatusAuditEntry
                      key={generateMappingKey(id)}
                      responseBody={responseBody}
                      requestor={requestorName}
                      timestamp={timestamp}
                    />
                  )
                )}
              </>
            );
          default:
            return <></>;
        }
      default:
        return <></>;
    }
  };

  return <>{mapperSwitch(operationCategories)}</>;
};

export default AuditLogEntryMapper;
