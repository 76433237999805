import { capitaliseString } from "helpers/GeneralHelpers";

const TagOptionMapper = ({ tags }) => {
  return (
    <>
      {tags?.map(({ tagId, tagName }) => (
        <option key={tagId} value={tagName}>
          {capitaliseString(tagName)}
        </option>
      ))}
    </>
  );
};

export default TagOptionMapper;
