import { ICreateAccountRequest } from "interfaces/Account/ICreateAccountRequest";
import { IGetAccountsRequest } from "interfaces/Account/IGetAccountsRequest";
import { IUpdateAccountRequest } from "interfaces/Account/IUpdateAccountRequest";

export const accountApiBaseUrl = process.env.REACT_APP_API_BASE_URL!;

export const defaultGetAccountsRequest: IGetAccountsRequest = {
  OrganisationId: null,
  LicenceTypeId: null,
  SortBy: "ascending",
  SearchValue: null,
};

export const defaultCreateAccountRequest: ICreateAccountRequest = {
  firstName: "",
  lastName: "",
  email: "",
  licenceTypeId: "",
  organisationId: "",
  licenceExpiry: "",
};

export const defaultAccountErrorState = {
  firstName: false,
  lastName: false,
  email: false,
  licenceType: false,
  licenseExpiry: false,
};

export const defaultUpdateAccountRequest: IUpdateAccountRequest = {
  email: "",
  firstName: "",
  lastName: "",
  profilePictureUrl: "",
  licenceTypeId: "",
  licenceExpiry: "",
  organisationId: "",
  suspended: false,
  archived: false,
};
