import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IOrganisation } from "../../interfaces/Organisation/IOrganisation";
import FormFooter from "../Footers/FormFooter";
import { useForm } from "react-hook-form";
import AlertMessage from "../Message/AlertMessage";
import { useNavigate } from "react-router-dom";
import Loader from "../Loaders/Loader";
import { IButtonProps } from "interfaces/General/IButtonProps";
import { fetchHandleCreateOrganisation } from "helpers/Organisations/OrganisationHelpers";
import {
  defaultCreateOrganisationRequest,
  defaultOrganisationErrorState,
} from "helpers/constants/OrganisationConstants";
import TagInput from "components/Tags/TagInput";
import { TokenContext } from "helpers/constants/Contexts";
import { Form, Stack } from "react-bootstrap";
import ModalComponent from "components/Modal/ModalComponent";
import { ICreateOrganisationRequestObject } from "interfaces/Organisation/ICreateOrganisationRequest";

const CreateOrganisationComponent = () => {
  //#region constants
  const accessToken = useContext(TokenContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit } = useForm();

  //#endregion

  //#region useStates
  const [cancelUrl] = useState("/organisations");
  const [organisation, setOrganisation] = useState(
    defaultCreateOrganisationRequest
  );
  const [validated, setValidated] = useState(false);
  const [errorState, setErrorState] = useState(defaultOrganisationErrorState);
  const [disabled, setDisabled] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState<string>();
  const [failure, setFailure] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  //#endregion

  //#region random
  const primaryBtn: IButtonProps = {
    text: "Submit",
    onClick: () => handleSubmit(onSubmit)(),
    disabled: disabled,
  };
  const secondaryBtn: IButtonProps = {
    text: "Cancel",
    onClick: () => onCancel(),
    disabled: disabled,
  };
  //#endregion

  //#region useEffects
  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  useEffect(() => {
    if (validated) {
      setMessage("Please fill out the required fields.");
      setFailure(true);
      setShowMessage(true);
      window.scrollTo(0, 0);
    }
  }, [validated]);
  //#endregion

  //#region functions
  const handleChange = (event) => {
    setOrganisation({
      ...organisation,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = () => {
    if (!errorState.organisationName && !errorState.organisationEmail) {
      sendCreateOrganisationRequest(organisation);
    } else {
      setValidated(true);
    }
    window.scrollTo(0, 0);
  };

  const validate = () => {
    setErrorState({
      ...errorState,
      organisationName: organisation.organisationName.trim().length === 0,
      organisationEmail: !/\S+@\S+\.\S+/.test(organisation.organisationEmail),
    });
  };

  const onCreateOrganisationFailure = (message) => {
    setDisabled(false);
    setMessage(message);
    setFailure(true);
    setShowMessage(true);
    window.scrollTo(0, 0);
  };

  const onCreateOrganisationSuccess = (organisation: IOrganisation) => {
    setDisabled(false);
    setOrganisation(defaultCreateOrganisationRequest);
    navigate(
      `/organisations/success?orgId=${organisation.organisationId}&orgName=${organisation.organisationName}`
    );
  };

  const sendCreateOrganisationRequest = async (
    organisation: ICreateOrganisationRequestObject
  ) => {
    setDisabled(true);
    dispatch(
      fetchHandleCreateOrganisation(
        accessToken,
        organisation,
        (organisation) => onCreateOrganisationSuccess(organisation),
        (message) => onCreateOrganisationFailure(message)
      )
    );
  };

  const onTagsUpdated = (updatedTags: string[]) => {
    setOrganisation({
      ...organisation,
      organisationTags: updatedTags,
    });
  };

  const onCancel = () => {
    if (
      JSON.stringify(organisation) !==
      JSON.stringify(defaultCreateOrganisationRequest)
    ) {
      setShowAlert(true);
    } else {
      navigate(cancelUrl!);
      return;
    }
  };

  const handleClose = () => {
    setShowAlert(false);
  };
  //#endregion

  return (
    <>
      <div className="form-page">
        {disabled ? (
          <Loader />
        ) : (
          <div className="vstack gap-5 col-md-5 mx-auto">
            <h3 className="form_heading">Organisation Details</h3>
            {showMessage && (
              <AlertMessage
                success={!failure}
                title={failure ? "Error" : "Success"}
                message={message}
              />
            )}
            <Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="vertical" gap={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Organisation Name</Form.Label>
                  <Form.Text className="text-muted">
                    Enter the name of the organisation
                  </Form.Text>
                  <Form.Control
                    size="lg"
                    name="organisationName"
                    type="text"
                    placeholder="My Organisation Ltd"
                    value={organisation.organisationName}
                    onChange={handleChange}
                    disabled={disabled}
                    isInvalid={errorState.organisationName && validated}
                    isValid={!errorState.organisationName && validated}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Organisation Email</Form.Label>
                  <Form.Text className="text-muted">
                    Enter the primary email address for this organisation
                  </Form.Text>
                  <Form.Control
                    size="lg"
                    name="organisationEmail"
                    type="email"
                    placeholder="example@organisation.com"
                    value={organisation.organisationEmail}
                    onChange={handleChange}
                    disabled={disabled}
                    isInvalid={errorState.organisationEmail && validated}
                    isValid={!errorState.organisationEmail && validated}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Organisation description</Form.Label>
                  <Form.Text className="text-muted">
                    Enter a description for the organisation
                  </Form.Text>
                  <Form.Control
                    as="textarea"
                    maxLength={1500}
                    rows={3}
                    size="lg"
                    name="organisationDescription"
                    type="text"
                    placeholder="A short description of the organisation max length 1500 characters."
                    value={organisation.organisationDescription}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Form.Group>

                <TagInput
                  tagList={organisation.organisationTags}
                  onTagsUpdated={onTagsUpdated}
                />
              </Stack>
            </Form>
          </div>
        )}
      </div>
      <FormFooter primaryBtn={primaryBtn} secondaryBtn={secondaryBtn} />
      <ModalComponent
        actionHandler={() => navigate(cancelUrl)}
        handleClose={handleClose}
        displayModal={showAlert}
        action={"Yes"}
        modalTitle={"Cancel Organisation Changes"}
        modalBody={
          "Your unsaved changes will be lost are you sure you want to cancel?"
        }
      />
    </>
  );
};

export default CreateOrganisationComponent;
