import { useNavigate } from "react-router-dom";
import { Card, Stack, Button } from "react-bootstrap";

import OrganisationTagsComponent from "./OrganisationTags";
import ThumbnailComponent from "../Thumbnail/ThumbnailComponent";
import { highlightMatchInString } from "helpers/GeneralHelpers";
import Status from "components/General/Status";
import OrganisationAuditWindow from "components/Audit/OrganisationAuditWindow";
import { useState } from "react";
import SvgBtn from "components/General/SvgBtn";
import infoBtn from "assets/images/info_btn.svg";

const OrganisationItemComponent = ({
  organisation,
  onTagSelected,
  searchValue,
}) => {
  const navigate = useNavigate();
  const manageProfileUrl = `/organisations/${organisation.organisationId}`;
  const viewOrganisationAccountsUrl = `/organisations/${organisation.organisationId}/?accounts=1`;
  const [showAudit, setShowAudit] = useState(false);

  return (
    <>
      <Card key={organisation.organisationId}>
        <Stack className="m-4" direction="vertical">
          <Stack direction="horizontal" gap={4}>
            <ThumbnailComponent
              organisationName={organisation.organisationName}
              size={40}
            />
            <h1 className="flex-grow-1 mx-3">
              {highlightMatchInString(
                searchValue,
                organisation.organisationName
              )}
            </h1>
            <Stack direction="horizontal" gap={4}>
              <SvgBtn icon={infoBtn} onClick={() => setShowAudit(true)} />
              <label className="form-label font-weight-bold">Status:</label>
              <Status
                suspended={organisation.suspended}
                archived={organisation.archived}
              />
            </Stack>
            <Button
              className="ms-auto"
              variant="secondary"
              onClick={() => navigate(manageProfileUrl)}
            >
              Manage profile
            </Button>
          </Stack>
        </Stack>
        <Card.Body>
          <Stack direction="vertical" gap={3}>
            <Stack direction="horizontal" gap={3}>
              <label className="custom_label grey">
                {`Accounts: ${organisation.numberOfAccounts}`}{" "}
              </label>
              <Button
                variant="link"
                onClick={() => navigate(viewOrganisationAccountsUrl)}
              >
                View
              </Button>
            </Stack>

            <OrganisationTagsComponent
              title="Tags: "
              tags={organisation.tags}
              onSelected={onTagSelected}
            />
          </Stack>
        </Card.Body>
      </Card>
      <OrganisationAuditWindow
        organisation={organisation}
        show={showAudit}
        handleClose={() => setShowAudit(false)}
      ></OrganisationAuditWindow>
    </>
  );
};

export default OrganisationItemComponent;
