import { useEffect, useRef, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import ProfileImageComponent from "./ProfileImage";

const ImageSelector = ({ imageUrl, onChange, onDeleted, altStr, disabled }) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string | null>();
  const [displayImg, setDisplayImg] = useState<string>();
  const [imgLocal, setImgLocal] = useState(false);

  useEffect(() => {
    let path = imageUrl;
    if (!imgLocal) {
      path = `${path}?${new Date().getTime()}`;
    }
    setDisplayImg(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  const onBrowseClick = () => {
    if (inputFile.current != null) {
      inputFile.current.click();
    }
  };

  const onDeleteClick = () => {
    if (inputFile.current?.value != null) {
      inputFile.current.value = "";
      onDeleted();
      setFileName(null);
    }
  };

  const handleFileChange = (event) => {
    onChange(event);
    setFileName(event.target.files[0].name);
    setImgLocal(true);
  };

  return (
    <>
      <Stack className="mt-3" direction="horizontal" gap={3}>
        <ProfileImageComponent altStr={altStr} imgToDisplay={displayImg} />
        <Button
          variant="link"
          id="Browse"
          type="button"
          onClick={onBrowseClick}
          disabled={disabled}
        >
          Browse
        </Button>
        <input
          type="file"
          id="pictureSelector"
          name="ProfilePicture"
          ref={inputFile}
          accept="image/png, image/jpeg, image/jpg, image/gif"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <Form.Label className="text-center" htmlFor="pictureSelector">
          {fileName}
        </Form.Label>
        <Button
          variant="link"
          id="Delete"
          type="button"
          onClick={onDeleteClick}
          disabled={disabled}
        >
          Delete
        </Button>
      </Stack>
    </>
  );
};

export default ImageSelector;
