import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Image from "react-bootstrap/Image";

const ProfileImageComponent = ({ imgToDisplay, altStr }) => {
  const [img, setImg] = useState();
  const [invalid, setInvalid] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setImg(imgToDisplay);
    setInvalid(false);
  }, [imgToDisplay]);

  const onError = () => {
    setInvalid(true);
  };

  if (!invalid) {
    return (
      <>
        <Spinner
          hidden={loaded}
          animation={"border"}
          style={{ color: "#2CCCD3" }}
        />
        <Image
          onLoad={() => setLoaded(true)}
          className="img_preview"
          hidden={!loaded}
          roundedCircle
          src={img}
          alt=""
          onError={onError}
        />
      </>
    );
  } else {
    return (
      <>
        <div className="img_letter">{altStr}</div>
      </>
    );
  }
};

export default ProfileImageComponent;
